import React, { useEffect, useState } from "react";
import Meta from "../components/settings/Meta";
import PricingSection from "../components/general/PricingSection";
import { useAuth } from "../util/auth";
import { checkAccessStatus, getRemainingTrialDays } from "../util/db";

function PricingPage(props) {
  const auth = useAuth();
  const [accessStatus, setAccessStatus] = useState(null);

  useEffect(() => {
    if (auth.user) {
      checkAccessStatus(auth.user.uid)
        .then(status => setAccessStatus(status));
    }
  }, [auth.user]);

  const getPricingContent = () => {
    switch(accessStatus) {
      case 'trial_expired':
        return {
          title: "Continue Your Benchmarking Journey",
          subtitle: "Your 14-day free trial has ended. Upgrade now to keep accessing benchmarks from 600+ companies.",
          showUpgradeButton: true,
          strapline: "Choose Your Plan"
        };
      case 'subscribed':
        return {
          title: "You're on the Pro Plan",
          subtitle: "Thank you for being a valued customer. You have full access to all features and benchmarking data.",
          showUpgradeButton: false,
          strapline: "Your Current Plan"
        };
      case 'trial_active':
        return {
          title: "Unlock Full Access",
          subtitle: `You have ${getRemainingTrialDays(auth.user.trial_end)} days left in your trial.`,
          showUpgradeButton: true,
          strapline: "Choose Your Plan"
        };
      default:
        return {
          title: "Data-Driven People Decisions Start Here",
          subtitle: "Get instant access to benchmarking data from 600+ startups and scaleups. Compare your metrics, spot trends, and make informed decisions.",
          showUpgradeButton: true,
          strapline: "Choose Your Plan"
        };
    }
  };

  const content = getPricingContent();

  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        title={content.title}
        subtitle={content.subtitle}
        showUpgradeButton={content.showUpgradeButton}
        strapline={content.strapline}
        size="md"
        bgColor=""
        bgImage="../images/background-faqs.jpg"
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default PricingPage;
