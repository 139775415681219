import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from './auth';
import { loadUserPreferences, saveUserPreferences } from './db';
import { stats, columnMapping } from './MetricsConfig';

const CurrencyContext = createContext();

export const CURRENCY_SYMBOLS = {
  USD: '$',
  EUR: '€',
  GBP: '£'
};

export const CURRENCY_RATES = {
  USD: 1,
  EUR: 0.85,
  GBP: 0.73
};

export const useCurrency = () => useContext(CurrencyContext);

export const CurrencyProvider = ({ children }) => {
  const auth = useAuth();
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [convertedData, setConvertedData] = useState(null);

  // Load user's currency preference
  useEffect(() => {
    const loadPreference = async () => {
      if (!auth.user?.uid) return;
      try {
        const prefs = await loadUserPreferences(auth.user.uid);
        if (prefs?.currency) {
          setSelectedCurrency(prefs.currency);
        }
      } catch (error) {
        console.error('Error loading currency preference:', error);
      }
    };
    loadPreference();
  }, [auth.user?.uid]);

  // Convert from USD to selected currency
  const convertFromUSD = useCallback((value, metricName) => {
    if (value == null || isNaN(value)) return value;
    return value * CURRENCY_RATES[selectedCurrency];
  }, [selectedCurrency]);

  // Convert from selected currency to USD
  const convertToUSD = useCallback((value, metricName) => {
    if (value == null || isNaN(value)) return value;
    return value / CURRENCY_RATES[selectedCurrency];
  }, [selectedCurrency]);

  const convertDataset = useCallback((dataset) => {
    if (!dataset) return dataset;
    
    return dataset.map(item => {
      const convertedItem = { ...item };
      stats.forEach(metric => {
        if (metric.isCurrency && item[columnMapping[metric.name]] != null) {
          const value = parseFloat(item[columnMapping[metric.name]]);
          if (!isNaN(value)) {
            // Store both USD and converted values
            convertedItem[`${columnMapping[metric.name]}_usd`] = value;
            convertedItem[columnMapping[metric.name]] = convertFromUSD(value, metric.name);
          }
        }
      });
      return convertedItem;
    });
  }, [selectedCurrency, convertFromUSD]);

  // Update currency and convert all data
  const updateCurrency = async (newCurrency) => {
    if (!auth.user?.uid) return;
    
    try {
      await saveUserPreferences(auth.user.uid, { currency: newCurrency });
      setSelectedCurrency(newCurrency);
    } catch (error) {
      console.error('Error saving currency preference:', error);
    }
  };

  return (
    <CurrencyContext.Provider value={{
      selectedCurrency,
      currencySymbol: CURRENCY_SYMBOLS[selectedCurrency],
      updateCurrency,
      convertDataset,
      convertFromUSD,
      convertToUSD,
      rates: CURRENCY_RATES
    }}>
      {children}
    </CurrencyContext.Provider>
  );
}; 