import React, { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import { useMetrics } from '../../util/UserMetricsContext';
import MetricTrendChart from './MetricTrendChart';
import { useAuth } from '../../util/auth';
import { fetchMetricsByOwnerAndPeriod, fetchAggregateMetrics } from '../../util/db';
import { getCurrentQuarter } from '../../util/MetricsConfig';
import { columnMapping, stats } from '../../util/MetricsConfig';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import DownloadChartModal from '../modals/DownloadChartModal';
import NewFeatureFlag from '../general/NewFeatureFlag';
import { useCurrency } from '../../util/CurrencyContext';

const TrendAnalysis = () => {
  const auth = useAuth();
  const { metricsData, setMetricsData } = useMetrics();
  const [selectedMetric, setSelectedMetric] = useState('engagement');
  const [selectedStage, setSelectedStage] = useState('All Stages');
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [showCallout, setShowCallout] = useState(true);
  const chartRef = useRef(null);

  // Move getMetricOptions function definition here, before it's used
  const getMetricOptions = () => {
    if (!metricsData.quarterlyData) return [];

    const allKeys = Object.keys(metricsData.quarterlyData).reduce((keys, quarter) => {
      const quarterKeys = Object.keys(metricsData.quarterlyData[quarter] || {});
      return [...new Set([...keys, ...quarterKeys])];
    }, []);

    const filteredKeys = allKeys.filter(key => 
      !['id', 'createdAt', 'owner', 'time_period', 'year', 'updatedAt'].includes(key)
    );

    const mappedOptions = filteredKeys.map(key => {
      const prettyName = Object.keys(columnMapping).find(k => columnMapping[k] === key) || key;
      const metricStats = stats.find(stat => stat.name === prettyName) || {};
      const label = metricStats.name || prettyName.charAt(0).toUpperCase() + prettyName.slice(1);
      const unit = metricStats.unit || '';
      return {
        id: key,
        name: label,
        label: label,
        unit: unit,
        icon: metricStats.icon
      };
    });

    return mappedOptions.sort((a, b) => a.name.localeCompare(b.name));
  };

  // Memoize loading state
  const isLoading = useMemo(() => {
    return !metricsData.quarterlyData || !metricsData.aggregateData;
  }, [metricsData.quarterlyData, metricsData.aggregateData]);

  // Memoize metric options
  const metricOptions = useMemo(() => getMetricOptions(), [metricsData.quarterlyData]);

  // Memoize selected metric option
  const selectedMetricOption = useMemo(() => {
    return metricOptions.find(option => option.id === selectedMetric);
  }, [metricOptions, selectedMetric]);

  // Memoize percentiles calculation
  const calculatePercentiles = useMemo(() => {
    if (!metricsData?.aggregateData || !selectedMetric) return { percentiles: [], bins: [] };

    const allData = Object.values(metricsData.aggregateData)
      .map(period => period[selectedMetric])
      .filter(Boolean);

    if (allData.length === 0) return { percentiles: [], bins: [] };

    // Extract percentiles and bins from the aggregate data
    const percentiles = [];
    const bins = [];

    allData.forEach(periodData => {
      if (periodData.median) {
        percentiles.push(periodData.median);
      }
      ['bottom25', 'lower25_50', 'upper50_75', 'top25'].forEach(range => {
        if (periodData[range]?.min !== undefined) bins.push(periodData[range].min);
        if (periodData[range]?.max !== undefined) bins.push(periodData[range].max);
      });
    });

    return {
      percentiles: [...new Set(percentiles)].sort((a, b) => a - b),
      bins: [...new Set(bins)].sort((a, b) => a - b)
    };
  }, [metricsData.aggregateData, selectedMetric]);

  // Single log for component state
  useEffect(() => {
    // console.log('🟡 TrendAnalysis: State Update', {
    //   hasQuarterlyData: !!metricsData.quarterlyData,
    //   quarterlyDataKeys: Object.keys(metricsData.quarterlyData || {}),
    //   hasAggregateData: !!metricsData.aggregateData,
    //   aggregateDataLength: Array.isArray(metricsData.aggregateData) ? metricsData.aggregateData.length : 0,
    //   selectedMetric,
    //   selectedStage,
    //   isLoading
    // });
  }, [metricsData, selectedMetric, selectedStage, isLoading]);

  const { convertFromUSD } = useCurrency();

  // console.log('🟡 TrendAnalysis: Initial render', {
  //   contextData: metricsData,
  //   selectedMetric,
  //   selectedStage
  // });

  const peopleMetrics = metricOptions.filter(metric => 
    stats.find(stat => stat.name === metric.name && stat.metricType === 'People')
  );
  const talentMetrics = metricOptions.filter(metric => 
    stats.find(stat => stat.name === metric.name && stat.metricType === 'Talent')
  );

  const stageOptions = ['All Stages', 'Seed', 'Series A', 'Series B', 'Series C+'];

  const LoadingOverlay = () => (
    <div className="absolute inset-x-0 bottom-0 h-full bg-white bg-opacity-75 z-50 flex items-center justify-center">
      <div className="text-center transform -translate-y-1/4">
        <ArrowPathIcon className="h-12 w-12 text-indigo-500 animate-spin mx-auto" />
        <p className="mt-2 text-sm font-medium text-gray-700">Loading data...</p>
      </div>
    </div>
  );

  const handleMetricChange = useCallback((metricId) => {
    setSelectedMetric(metricId);
  }, []);

  const handleStageChange = useCallback((stage, close) => {
    setSelectedStage(stage);
    close();
  }, []);

  const handleCalloutToggle = useCallback(() => {
    setShowCallout(prev => !prev);
  }, []);

  const renderChartForDownload = useCallback(() => {
    return (
      <MetricTrendChart 
        metricId={selectedMetric} 
        yAxisLabel={metricOptions.find(option => option.id === selectedMetric)?.label}
        yAxisUnit={metricOptions.find(option => option.id === selectedMetric)?.unit}
        selectedStage={selectedStage}
        showUserValue={true}
        aggregateData={metricsData.aggregateData}
        showLegendButtons={false}
        showCallout={showCallout}
        onCalloutToggle={handleCalloutToggle}
      />
    );
  }, [selectedMetric, metricOptions, selectedStage, metricsData.aggregateData, showCallout, handleCalloutToggle]);

  // Add this helper function to sort quarters correctly
  const sortQuarters = (a, b) => {
    const [yearA, quarterA] = a.split(' ');
    const [yearB, quarterB] = b.split(' ');
    return yearA === yearB ? 
      quarterA.localeCompare(quarterB) : 
      parseInt(yearA) - parseInt(yearB);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const rawData = await fetchAggregateMetrics(selectedStage);
        
        // Group and process the raw data by quarter
        const groupedData = rawData.reduce((acc, record) => {
          const quarter = `${record.year} ${record.time_period}`;
          
          if (!acc[quarter]) {
            acc[quarter] = {
              quarter,
              sampleSize: 0,
              median: record[selectedMetric],
              bottom25: {
                min: Math.min(...rawData.map(d => d[selectedMetric])),
                max: record[selectedMetric] * 0.25
              },
              lower25_50: {
                min: record[selectedMetric] * 0.25,
                max: record[selectedMetric] * 0.5
              },
              upper50_75: {
                min: record[selectedMetric] * 0.5,
                max: record[selectedMetric] * 0.75
              },
              top25: {
                min: record[selectedMetric] * 0.75,
                max: Math.max(...rawData.map(d => d[selectedMetric]))
              }
            };
          }
          acc[quarter].sampleSize++;
          return acc;
        }, {});

        // Sort quarters chronologically
        const sortedData = Object.entries(groupedData)
          .sort(([quarterA], [quarterB]) => sortQuarters(quarterA, quarterB))
          .reduce((acc, [quarter, data]) => {
            acc[quarter] = data;
            return acc;
          }, {});

        // Calculate percentiles for the selected metric
        const allValues = rawData.map(record => record[selectedMetric]).filter(Boolean);
        const percentiles = {};
        Object.keys(sortedData).forEach(quarter => {
          const value = sortedData[quarter].median;
          if (value) {
            const percentileRank = (allValues.filter(v => v < value).length / allValues.length) * 100;
            percentiles[quarter] = {
              [selectedMetric]: percentileRank
            };
          }
        });

        setMetricsData(prev => ({
          ...prev,
          aggregateData: sortedData,
          quarterlyData: prev.quarterlyData || {},
          percentiles: percentiles
        }));

      } catch (error) {
        console.error('Error loading aggregate data:', error);
      }
    };

    loadData();
  }, [selectedStage, selectedMetric]);

  if (!metricsData || !metricsData.quarterlyData || Object.keys(metricsData.quarterlyData).length === 0) {
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4">
        <p className="text-gray-500">No metrics data available.</p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow overflow-visible sm:rounded-lg relative">
      <div className="px-4 py-5 sm:px-6">
        <div className="flex items-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mr-6">Trend Analysis</h3>
          <NewFeatureFlag tooltipText="💡 This is a beta feature and some combinations of metric and stage may not have enough data to give a comprehensive time series. We are working hard to onboard more startups and scaleups to improve this service for you." />
        </div>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">View trends for your metrics over time</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div className="py-4 sm:py-5 sm:px-3">
          <div className="flex items-center space-x-4">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-50 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 group">
                  {selectedMetricOption ? (
                    <>
                      {selectedMetricOption.icon && (
                        <selectedMetricOption.icon className="mr-2 h-5 w-5 text-gray-400 group-hover:text-indigo-600" aria-hidden="true" />
                      )}
                      {selectedMetricOption.name}
                    </>
                  ) : (
                    'Select a metric'
                  )}
                  <ChevronDownIcon aria-hidden="true" className="ml-2 -mr-1 h-5 w-5 text-gray-400 group-hover:text-indigo-600" />
                </Menu.Button>
              </div>

              <Menu.Items className="absolute right-0 z-10 mt-2 min-w-[15rem] max-w-full max-h-60 overflow-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform -translate-y-full sm:translate-y-0">
                <div className="py-1">
                  <div className="px-4 py-2 text-xs font-semibold text-gray-500">People Metrics</div>
                  {peopleMetrics.map((metric) => (
                    <Menu.Item key={metric.id}>
                      {({ active }) => (
                        <div
                          onClick={() => handleMetricChange(metric.id)}
                          className={`group flex items-center px-4 py-2 text-sm w-full text-left cursor-pointer truncate ${
                            active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-700 hover:bg-indigo-50 hover:text-indigo-600'
                          }`}
                        >
                          {metric.icon && (
                            <metric.icon className={`mr-3 h-5 w-5 ${
                              active ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600'
                            }`} aria-hidden="true" />
                          )}
                          <span className="truncate">{metric.name}</span>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </div>
                <div className="py-1">
                  <div className="px-4 py-2 text-xs font-semibold text-gray-500">Talent Metrics</div>
                  {talentMetrics.map((metric) => (
                    <Menu.Item key={metric.id}>
                      {({ active }) => (
                        <div
                          onClick={() => handleMetricChange(metric.id)}
                          className={`group flex items-center px-4 py-2 text-sm w-full text-left cursor-pointer truncate ${
                            active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-700 hover:bg-indigo-50 hover:text-indigo-600'
                          }`}
                        >
                          {metric.icon && (
                            <metric.icon className={`mr-3 h-5 w-5 ${
                              active ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600'
                            }`} aria-hidden="true" />
                          )}
                          <span className="truncate">{metric.name}</span>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Menu>

            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-50 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                  {selectedStage}
                  <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {stageOptions.map((stage) => (
                    <Menu.Item key={stage}>
                      {({ active, close }) => (
                        <a
                          href="#"
                          className={`${
                            active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-700 hover:bg-indigo-50 hover:text-indigo-600'
                          } block px-4 py-2 text-sm`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleStageChange(stage, close);
                          }}
                        >
                          {stage}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Menu>
          </div>
        </div>
        <div className="py-4 sm:py-5 sm:px-6 relative h-[400px]">
          {isLoading && <LoadingOverlay />}
          <div ref={chartRef} className="relative">
            <MetricTrendChart 
              metricId={selectedMetric} 
              yAxisLabel={metricOptions.find(option => option.id === selectedMetric)?.label}
              yAxisUnit={metricOptions.find(option => option.id === selectedMetric)?.unit}
              selectedStage={selectedStage}
              showUserValue={true}
              aggregateData={metricsData.aggregateData || {}}
              percentiles={metricsData.percentiles || {}}
              bins={calculatePercentiles.bins || []}
              showCallout={showCallout}
              onCalloutToggle={handleCalloutToggle}
            />
            <button
              onClick={() => setIsDownloadModalOpen(true)}
              className="absolute top-0 right-2 p-1"
            >
              <ArrowDownTrayIcon className="h-5 w-5 text-gray-600 hover:text-indigo-500" />
            </button>
          </div>
        </div>
      </div>
      {isDownloadModalOpen && (
        <DownloadChartModal
          isOpen={isDownloadModalOpen}
          onClose={() => setIsDownloadModalOpen(false)}
          title="Download Trend Analysis Chart"
          message="Download your trend analysis chart as an image."
          renderChart={renderChartForDownload}
          fileName="trend_analysis_chart.png"
        />
      )}
    </div>
  );
};

export default memo(TrendAnalysis);