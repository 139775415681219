import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './auth';
import { fetchMetricsByOwnerAndPeriod, fetchAggregateMetrics } from './db';
import { getCurrentQuarter } from './MetricsConfig';
import { useCurrency } from './CurrencyContext';
import { stats, columnMapping } from './MetricsConfig';

const UserMetricsContext = createContext();

export const useMetrics = () => useContext(UserMetricsContext);

export const MetricsProvider = ({ children }) => {
  const auth = useAuth();
  const { convertDataset, convertFromUSD, selectedCurrency } = useCurrency();
  const [metricsData, setMetricsData] = useState({
    orgCount: 0,
    allMetricsData: [],
    metricSampleSizes: {},
    userMetricsData: {},
    quarterlyData: {},
    aggregateData: {}
  });

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!auth.user?.uid) return;

      try {
        // console.log('🔵 Context: Starting data fetch');
        
        const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
        const currentYear = new Date().getFullYear();
        const currentQuarter = getCurrentQuarter();
        const quarterlyData = {};

        // Fetch quarterly data
        for (let i = 0; i < 4; i++) {
          if (!isMounted) return;

          let year = currentYear;
          let quarterIndex = quarters.indexOf(currentQuarter) - i;
          if (quarterIndex < 0) {
            year--;
            quarterIndex += 4;
          }
          const quarter = quarters[quarterIndex];
          const fetchedMetrics = await fetchMetricsByOwnerAndPeriod(auth.user.uid, year, quarter);
          
          if (fetchedMetrics.length > 0) {
            quarterlyData[`${year} ${quarter}`] = fetchedMetrics[0];
          }
        }

        // Fetch aggregate data
        if (!isMounted) return;
        const aggregateData = await fetchAggregateMetrics();

        if (!isMounted) return;

        // console.log('🔵 Context: Raw data fetched', {
        //   quarterlyDataKeys: Object.keys(quarterlyData),
        //   aggregateDataType: Array.isArray(aggregateData) ? 'array' : typeof aggregateData,
        //   aggregateDataLength: Array.isArray(aggregateData) ? aggregateData.length : 0,
        //   sampleQuarterlyData: Object.keys(quarterlyData).length > 0 
        //     ? quarterlyData[Object.keys(quarterlyData)[0]] 
        //     : null
        // });

        // Convert currency values in quarterly data
        const convertedQuarterlyData = {};
        Object.entries(quarterlyData).forEach(([period, data]) => {
          convertedQuarterlyData[period] = {};
          Object.entries(data).forEach(([key, value]) => {
            const metricName = Object.keys(columnMapping).find(k => columnMapping[k] === key);
            const metricConfig = stats.find(stat => stat.name === metricName);
            if (metricConfig?.isCurrency && value != null) {
              convertedQuarterlyData[period][key] = convertFromUSD(value);
            } else {
              convertedQuarterlyData[period][key] = value;
            }
          });
        });

        // Convert currency values in aggregate data
        const convertedAggregateData = aggregateData.map(item => {
          const converted = { ...item };
          Object.entries(item).forEach(([key, value]) => {
            const metricName = Object.keys(columnMapping).find(k => columnMapping[k] === key);
            const metricConfig = stats.find(stat => stat.name === metricName);
            if (metricConfig?.isCurrency && value != null) {
              if (typeof value === 'object') {
                // Handle nested objects (like ranges and medians)
                const convertedValue = {};
                Object.entries(value).forEach(([subKey, subValue]) => {
                  if (typeof subValue === 'number') {
                    convertedValue[subKey] = convertFromUSD(subValue);
                  } else if (typeof subValue === 'object') {
                    // Handle min/max objects
                    convertedValue[subKey] = {
                      min: subValue.min != null ? convertFromUSD(subValue.min) : subValue.min,
                      max: subValue.max != null ? convertFromUSD(subValue.max) : subValue.max
                    };
                  } else {
                    convertedValue[subKey] = subValue;
                  }
                });
                converted[key] = convertedValue;
              } else {
                converted[key] = convertFromUSD(value);
              }
            }
          });
          return converted;
        });

        if (!isMounted) return;

        // console.log('🔵 Context: Converted data', {
        //   convertedQuarterlyDataKeys: Object.keys(convertedQuarterlyData),
        //   convertedAggregateDataLength: convertedAggregateData.length,
        //   sampleConvertedQuarterlyData: Object.keys(convertedQuarterlyData).length > 0 
        //     ? convertedQuarterlyData[Object.keys(convertedQuarterlyData)[0]] 
        //     : null
        // });

        setMetricsData(prevState => ({
          ...prevState,
          quarterlyData: convertedQuarterlyData,
          aggregateData: convertedAggregateData
        }));

      } catch (error) {
        if (isMounted) {
          // console.error('🔴 Context Error:', error, error.stack);
        }
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [auth.user?.uid, selectedCurrency]);

  return (
    <UserMetricsContext.Provider value={{ metricsData, setMetricsData }}>
      {children}
    </UserMetricsContext.Provider>
  );
};