import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ArrowTrendingUpIcon, ArrowTrendingDownIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';
import { updateMetric, fetchMetricsByOwnerAndPeriod, createOrUpdateMetric } from '../../util/db';
import { useAuth } from '../../util/auth';
import { stats, columnMapping, invertedMetrics } from '../../util/MetricsConfig';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline';
import LoadingIcon from '../cosmetics/LoadingIcon';
import Button from '../cosmetics/Button';
import SuccessModal from '../modals/SuccessModal';
import HowToModal from '../modals/HowToModal';
import useFeatureGate from '../../util/FeatureGate';
import MetricCountAlert from '../cosmetics/MetricCountAlert';
import { calculateQuarterlyTrend, getCurrentQuarter } from "../../util/MetricsConfig";
import { useCurrency, CURRENCY_SYMBOLS } from '../../util/CurrencyContext';
import WarningModal from '../modals/WarningModal';
import { useMetrics } from '../../util/UserMetricsContext';

const calculateTrendPercentage = (current, previous) => {
  if (current == null || previous == null || isNaN(current) || isNaN(previous)) return null;
  // Use Math.abs(previous) to handle negative values correctly
  return (((current - previous) / Math.abs(previous)) * 100).toFixed(2) + '%';
};


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const EditMetricsTable = () => {
  const auth = useAuth();
  const ownerId = auth.user?.uid;
  const [metrics, setMetrics] = useState([]);
  const [previousMetricsState, setPreviousMetricsState] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metricsState, setMetricsState] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [savingStatus, setSavingStatus] = useState({});
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [successModalTitle, setSuccessModalTitle] = useState('');
  const [successModalMessage, setSuccessModalMessage] = useState('');
  const [openedModal, setOpenedModal] = useState(null);
  const [metricCount, setMetricCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedQuarter, setSelectedQuarter] = useState(getCurrentQuarter());
  const { 
    convertToUSD, 
    convertFromUSD, 
    currencySymbol, 
    selectedCurrency, 
    updateCurrency 
  } = useCurrency();
  const [showCurrencyModal, setShowCurrencyModal] = useState(false);
  const [hasExistingData, setHasExistingData] = useState(false);
  const [pendingCurrency, setPendingCurrency] = useState(null);
  const [displayValues, setDisplayValues] = useState({});
  const { metricsData } = useMetrics();

  const showModal = useCallback(() => setModalVisible(true), []);
  const { checkAccess } = useFeatureGate(['active', 'trialing'], metricCount, 3, showModal);
  const hasAccess = useMemo(() => checkAccess(false), [checkAccess]);

  const openModal = (metricKey) => {
    setOpenedModal(metricKey);
  };

  const closeModal = () => {
    setOpenedModal(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!ownerId) return;

      setIsLoading(true);
      try {
        const fetchedMetrics = await fetchMetricsByOwnerAndPeriod(ownerId, selectedYear, selectedQuarter);
        
        // Fetch previous quarter's metrics
        const previousQuarter = selectedQuarter === "Q1" ? "Q4" : `Q${parseInt(selectedQuarter.slice(1)) - 1}`;
        const previousYear = selectedQuarter === "Q1" ? selectedYear - 1 : selectedYear;
        const fetchedPreviousMetrics = await fetchMetricsByOwnerAndPeriod(ownerId, previousYear, previousQuarter);
        
        if (fetchedMetrics && fetchedMetrics.length > 0) {
          const data = fetchedMetrics[0];
          
          // Initialize both display and storage values
          const newMetricsState = {};
          const newDisplayValues = {};
          
          stats.forEach(({ name }) => {
            const metricKey = columnMapping[name];
            const metricConfig = stats.find(stat => stat.name === name);
            const value = data[metricKey];
            
            // Store original USD values
            newMetricsState[metricKey] = value ?? '';
            
            // Convert currency values for display
            if (metricConfig?.isCurrency && value !== null && value !== '') {
              newDisplayValues[metricKey] = convertFromUSD(parseFloat(value));
            } else {
              newDisplayValues[metricKey] = value ?? '';
            }
          });

          setMetricsState(newMetricsState);
          setDisplayValues(newDisplayValues);
          setPreviousMetricsState(fetchedPreviousMetrics[0] || {});
          setMetricCount(Object.keys(data).filter(key => 
            data[key] !== null && 
            !['id', 'owner', 'createdAt', 'updatedAt'].includes(key)
          ).length);
        } else {
          // Initialize empty states
          const emptyState = stats.reduce((acc, { name }) => {
            acc[columnMapping[name]] = '';
            return acc;
          }, {});
          setMetricsState(emptyState);
          setDisplayValues(emptyState);
          setMetricCount(0);
        }
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [ownerId, selectedYear, selectedQuarter, convertFromUSD]);

  useEffect(() => {
    if (metrics && metrics.length > 0) {
      setHasExistingData(true);
    }
  }, [metrics]);

  const orderedStats = [...stats].sort((a, b) => a.metricType.localeCompare(b.metricType));

  const handleInputChange = (metricKey, value, metricName) => {
    const cleanValue = value.replace(/[^0-9.]/g, '');
    const isValid = /^\d*\.?\d*$/.test(cleanValue);
    
    if (isValid || cleanValue === '') {
      const metricConfig = stats.find(stat => stat.name === metricName);
      const isCurrencyMetric = metricConfig?.isCurrency || false;
      
      // Always update display value with what user types
      setDisplayValues(prev => ({
        ...prev,
        [metricKey]: cleanValue
      }));

      // Convert to USD for storage if it's a currency metric
      if (isCurrencyMetric && cleanValue !== '') {
        const usdValue = convertToUSD(parseFloat(cleanValue));
        setMetricsState(prev => ({
          ...prev,
          [metricKey]: usdValue
        }));
      } else {
        setMetricsState(prev => ({
          ...prev,
          [metricKey]: cleanValue === '' ? null : parseFloat(cleanValue)
        }));
      }
      
      setValidationErrors(prev => ({
        ...prev,
        [metricKey]: false
      }));
    }
  };

  const handleSave = async (metricKey) => {
    const valueToStore = metricsState[metricKey];
    const data = {
      [metricKey]: valueToStore === '' ? null : valueToStore,
      year: selectedYear,
      time_period: selectedQuarter,
    };

    setSavingStatus(prev => ({ ...prev, [metricKey]: 'saving' }));
    try {
      await createOrUpdateMetric(ownerId, selectedYear, selectedQuarter, data);
      setSavingStatus(prev => ({ ...prev, [metricKey]: 'saved' }));
      setTimeout(() => setSavingStatus(prev => ({ ...prev, [metricKey]: '' })), 2000);
    } catch (error) {
      console.error('Error updating metric', error);
      setSavingStatus(prev => ({ ...prev, [metricKey]: 'error' }));
    }
  };

  const handleSaveAll = async () => {
    const data = Object.entries(metricsState).reduce((acc, [key, value]) => {
      if (!validationErrors[key] && value !== '') {
        // Find metric configuration
        const metricNameOriginal = Object.keys(columnMapping).find(k => columnMapping[k] === key);
        const metricConfig = stats.find(stat => stat.name === metricNameOriginal);
        
        // Convert to USD if it's a currency metric
        acc[key] = metricConfig?.isCurrency 
          ? convertToUSD(parseFloat(value))
          : value;
      }
      return acc;
    }, {});

    data.year = selectedYear;
    data.time_period = selectedQuarter;

    try {
      await updateMetric(ownerId, data);
      setSuccessModalTitle('Success!');
      setSuccessModalMessage('All your metrics have been saved successfully!');
      setSuccessModalOpen(true);
    } catch (error) {
      console.error('Failed to save metrics', error);
      setSuccessModalTitle('Error!');
      setSuccessModalMessage('There was a problem saving your metrics. Please try again.');
      setSuccessModalOpen(true);
    }
  };
  
  

  const handleCloseModal = () => {
    setSuccessModalOpen(false);
  };

  const renderTrend = (trend, isInverted, hasValue, isOpinionated) => {
    if (!hasValue) {
      return (
        <span className="text-gray-500">
          <ArrowTrendingUpIcon className="inline h-5 w-5 text-gray-500" aria-hidden="true" />
          N/A
        </span>
      );
    }

    const trendValue = parseFloat(trend);
    let icon = <ArrowLongRightIcon className="inline h-5 w-5 text-gray-500" aria-hidden="true" />;
    let textColor = "text-gray-800";

    if (isOpinionated) {
      if (trendValue > 0) {
        icon = <ArrowTrendingUpIcon className={`inline h-5 w-5 ${isInverted ? 'text-red-500' : 'text-green-500'}`} aria-hidden="true" />;
        textColor = isInverted ? "text-red-800" : "text-green-800";
      } else if (trendValue < 0) {
        icon = <ArrowTrendingDownIcon className={`inline h-5 w-5 ${isInverted ? 'text-green-500' : 'text-red-500'}`} aria-hidden="true" />;
        textColor = isInverted ? "text-green-800" : "text-red-800";
      }
    } else {
      if (trendValue !== 0) {
        icon = trendValue > 0 
          ? <ArrowTrendingUpIcon className="inline h-5 w-5 text-indigo-500" aria-hidden="true" />
          : <ArrowTrendingDownIcon className="inline h-5 w-5 text-indigo-500" aria-hidden="true" />;
      }
      textColor = "text-indigo-800";
    }

    return (
      <span className={`inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium ${textColor}`}>
        {icon}
        {trend}
      </span>
    );
  };

  const currentYear = new Date().getFullYear();
  const currentQuarter = getCurrentQuarter();
  const isFutureQuarter = (year, quarter) => {
    if (year > currentYear) return true;
    if (year === currentYear) {
      const quarterNumber = parseInt(quarter.slice(1), 10);
      const currentQuarterNumber = parseInt(currentQuarter.slice(1), 10);
      return quarterNumber > currentQuarterNumber;
    }
    return false;
  };

  const handleQuarterChange = (quarter) => {
    if (!isFutureQuarter(selectedYear, `Q${quarter}`)) {
      setSelectedQuarter(`Q${quarter}`);
    }
  };

  const handleYearChange = (year) => {
    if (!isFutureQuarter(year, selectedQuarter)) {
      setSelectedYear(year);
    }
  };

  const renderHowToModal = (metricKey) => {
    const metric = stats.find(stat => columnMapping[stat.name] === metricKey);
    if (!metric || !metric.howToCalculate) return null;

    const { description, example, conclusion, externalAssessment } = metric.howToCalculate;

    return (
      <HowToModal
        isOpen={openedModal === metricKey}
        onClose={closeModal}
        title={`${metric.name} Calculation`}
        message1={
          <p className="mb-4">{description}</p>
        }
        codeBlock={
          <>
            {example && (
              <>
                <p className="mb-4">📝 Example</p>
                {example.scenario && <p className="mb-4">{example.scenario}</p>}
                {example.steps && example.steps.map((step, index) => (
                  <p key={index}>
                    {step.includes("Take the org transparency assessment here") ? (
                      <>
                        Take the org transparency assessment <a href={externalAssessment.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 font-bold">here</a> to get your score
                      </>
                    ) : (
                      step
                    )}
                  </p>
                ))}
                {example.result && (
                  <>
                    <p>------------------------------</p>
                    <p>{example.result}</p>
                  </>
                )}
              </>
            )}
            {!example && <p>No example available for this metric.</p>}
          </>
        }
        message2={
          <p className="mb-4">👉 {conclusion || "No additional conclusion available."}</p>
        }
      />
    );
  };

  const renderCurrencySelector = () => (
    <div className="mb-6 bg-white rounded-lg border border-gray-200 p-4 shadow-sm">
      <div className="flex items-center justify-between space-x-8">
        <div className="flex-1">
          <h3 className="text-base font-semibold text-gray-900 mb-1">Currency Settings</h3>
          <p className="text-sm text-gray-500 leading-relaxed">
            {hasExistingData 
              ? `Your metrics are currently in ${selectedCurrency}. Changing currency will convert all your historical values to ${selectedCurrency}.`
              : "Select your preferred currency for entering metrics."}
          </p>
        </div>
        <div className="flex-shrink-0">
          <select
            value={selectedCurrency}
            onChange={(e) => handleCurrencyChange(e.target.value)}
            className="block w-36 rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm cursor-pointer bg-white shadow-sm"
          >
            {Object.entries(CURRENCY_SYMBOLS).map(([code, symbol]) => (
              <option key={code} value={code}>
                {code} ({symbol})
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );

  const handleCurrencyChange = (newCurrency) => {
    if (newCurrency !== selectedCurrency) {
      setPendingCurrency(newCurrency);
      setShowCurrencyModal(true);
    }
  };

  const handleConfirmCurrencyChange = async () => {
    if (pendingCurrency) {
      await updateCurrency(pendingCurrency);
      setPendingCurrency(null);
    }
    setShowCurrencyModal(false);
  };

  const formatValue = (value, isCurrency) => {
    if (!value && value !== 0) return '';
    
    // Convert currency values if needed
    const convertedValue = isCurrency ? convertFromUSD(value) : value;
    
    return convertedValue.toString();
  };

  const renderInput = (metricKey, name, unit, unitLocation) => {
    const metricConfig = stats.find(stat => stat.name === name);
    const isCurrencyMetric = metricConfig?.isCurrency || false;
    
    return (
      <div className="relative rounded-md shadow-sm">
        {(unit && unitLocation === 'front' || isCurrencyMetric) && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">
              {isCurrencyMetric ? currencySymbol : unit}
            </span>
          </div>
        )}
        <input
          type="text"
          value={displayValues[metricKey] || ''}
          onChange={(e) => handleInputChange(metricKey, e.target.value, name)}
          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
            unitLocation === 'front' || isCurrencyMetric ? 'pl-7' : ''
          } ${unit && unitLocation !== 'front' ? 'pr-7' : ''}`}
        />
        {unit && unitLocation !== 'front' && !isCurrencyMetric && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{unit}</span>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    console.log('Currency Modal State:', { showCurrencyModal, hasExistingData, pendingCurrency });
  }, [showCurrencyModal, hasExistingData, pendingCurrency]);

  useEffect(() => {
    if (metrics && metrics.length > 0) {
      const currencyMetrics = stats.filter(stat => stat.isCurrency);
      currencyMetrics.forEach(metric => {
        const dbColumn = columnMapping[metric.name];
        console.log(`Currency Metric: ${metric.name}`, {
          storedValue: metricsState[dbColumn],
          displayValue: displayValues[dbColumn],
          convertedValue: convertFromUSD(metricsState[dbColumn])
        });
      });
    }
  }, [metrics, metricsState, displayValues, convertFromUSD]);

  useEffect(() => {
    if (metrics && metrics.length > 0) {
      const data = metrics[0];
      const newDisplayValues = {};
      const newMetricsState = {};
      
      stats.forEach(({ name }) => {
        const metricKey = columnMapping[name];
        const metricConfig = stats.find(stat => stat.name === name);
        const value = data[metricKey];
        
        if (metricConfig?.isCurrency && value != null) {
          // For currency metrics, convert from USD for display
          newDisplayValues[metricKey] = convertFromUSD(value)?.toString() || '';
          newMetricsState[metricKey] = value; // Keep USD value in metrics state
        } else {
          // For non-currency metrics, use value as-is
          newDisplayValues[metricKey] = value?.toString() || '';
          newMetricsState[metricKey] = value;
        }
      });
      
      setDisplayValues(newDisplayValues);
      setMetricsState(newMetricsState);
    }
  }, [metrics, convertFromUSD]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading metrics</p>;

  return (
    <div className="container mx-auto p-2">
      {renderCurrencySelector()}
      
      <WarningModal
        isOpen={showCurrencyModal}
        onClose={() => {
          setShowCurrencyModal(false);
          setPendingCurrency(null);
        }}
        title="Change Currency?"
        message={`Changing from ${selectedCurrency} to ${pendingCurrency} will convert all your historical Cost of Hire and Annual Revenue Per Employee values to ${pendingCurrency}. Are you sure you want to proceed?
  `}
        buttonText="Yes, Change Currency"
        onConfirm={handleConfirmCurrencyChange}
      />

      <div className="flex space-x-4 pb-4">
        <div>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-500 shadow-sm ring-1 ring-inset ring-indigo-500 hover:bg-indigo-50">
                {selectedYear}
                <ChevronDownIcon className="-mr-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {[2024, 2025, 2026].map(year => (
                    <Menu.Item key={year}>
                      {({ active }) => (
                        <button
                          onClick={() => handleYearChange(year)}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm',
                            isFutureQuarter(year, selectedQuarter) && 'cursor-not-allowed opacity-50'
                          )}
                          disabled={isFutureQuarter(year, selectedQuarter)}
                        >
                          {year}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div>
          <Menu as="div" className="relative inline-block text-left">
            <div>
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-500 shadow-sm ring-1 ring-inset ring-indigo-500 hover:bg-indigo-50">
                {selectedQuarter}
                <ChevronDownIcon className="-mr-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {[1, 2, 3, 4].map(quarter => (
                    <Menu.Item key={quarter}>
                      {({ active }) => (
                        <button
                          onClick={() => handleQuarterChange(quarter)}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm',
                            isFutureQuarter(selectedYear, `Q${quarter}`) && 'cursor-not-allowed opacity-50'
                          )}
                          disabled={isFutureQuarter(selectedYear, `Q${quarter}`)}
                        >
                          Q{quarter}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 table-fixed">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Metric</th>
              <th scope="col" className="w-1/6 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Calculation</th>
              <th scope="col" className="w-1/6 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Area</th>
              <th scope="col" className="w-1/6 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Quarterly Trend</th>
              <th scope="col" className="w-1/4 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Enter Result</th>
              <th scope="col" className="w-1/6 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 text-center">
            {orderedStats.map(({ name, unit, unitLocation, icon: Icon, metricType }) => {
              const metricKey = columnMapping[name];
              const metricConfig = stats.find(stat => stat.name === name);
              const isOpinionated = metricConfig?.opinionated ?? true; // Default to true if not specified
              const isError = validationErrors[metricKey];
              const inputRoundedClass = unitLocation === 'front' ? 'rounded-r-md' : 'rounded-l-md';
              const status = savingStatus[metricKey];
              const trend = calculateTrendPercentage(metricsState[metricKey], previousMetricsState[metricKey]);
              const isInverted = invertedMetrics.includes(name);
              const hasValue = metricsState[metricKey] !== '';
              return (
                <tr key={name}>
                  <td className="px-0 py-4 whitespace-nowrap text-sm">
                    <div className="flex font-medium items-center">
                      <Icon className="h-5 w-5 mr-2 text-indigo-400" />
                      {name}
                    </div>
                  </td>
                  <td className="px-0 py-4 whitespace-nowrap text-sm">
                    <Button
                      variant='simple'
                      size="sm"
                      onClick={() => openModal(metricKey)}
                      className="text-indigo-600"
                      startIcon={<CursorArrowRaysIcon className="w-4 h-4" />}
                    >
                      How to...
                    </Button>
                  </td>
                  <td className="px-0 py-4 whitespace-nowrap text-sm">
                    {metricType === 'People' ? (
                      <span className="inline-flex items-center rounded-md bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-700">
                        {metricType}
                      </span>
                    ) : metricType === 'Talent' ? (
                      <span className="inline-flex items-center rounded-md bg-purple-100 px-2.5 py-0.5 text-xs font-medium text-purple-700">
                        {metricType}
                      </span>
                    ) : null}
                  </td>
                  <td className="px-0 py-4 whitespace-nowrap text-sm">
                    {renderTrend(trend, isInverted, hasValue, isOpinionated)}
                  </td>
                  <td className="px-8 py-4 whitespace-nowrap text-sm">
                    <div className="flex justify-center items-center">
                      {renderInput(metricKey, name, unit, unitLocation)}
                      {isError && <ExclamationCircleIcon className="h-5 w-5 text-indigo-500 ml-2" />}
                      {metricsState[metricKey] === '' && (
                        <div className="flex items-center ml-2 pb-1">
                          <span className="pt-1 relative flex h-3 w-3">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-400 opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-indigo-500"></span>
                          </span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="px-0 py-4 whitespace-nowrap text-sm font-medium">
                    <div className="flex justify-center">
                      <div className="flex items-center justify-center w-24">
                        {status === 'saving' ? (
                          <>
                            <LoadingIcon className="h-6 w-6 text-indigo-500"></LoadingIcon>
                            <span className="ml-2">Saving...</span>
                          </>
                        ) : status === 'saved' ? (
                          <span className="text-indigo-500">Saved!</span>
                        ) : (
                          <button
                            onClick={() => handleSave(metricKey)}
                            className="text-indigo-500 hover:text-indigo-700"
                          >
                            Save
                          </button>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="container mx-auto p-4">
          <div className="flex justify-end pr-12">
            <Button
              onClick={handleSaveAll}
              variant='secondary'
            >
              Save All Metrics
            </Button>
          </div>
          <SuccessModal
            isOpen={isSuccessModalOpen}
            onClose={handleCloseModal}
            title={successModalTitle}
            message={successModalMessage}
          />
        </div>
      </div>
      {/* Render HowToModals */}
      {stats.map(({ name }) => {
        const metricKey = columnMapping[name];
        return renderHowToModal(metricKey);
      })}
    </div>
  );
};

export default EditMetricsTable;

