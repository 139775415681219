import React, { useEffect, useState } from 'react';
import { useAuth } from '../../util/auth';
import { Link } from '../../util/router';

export function TrialExpirationModal() {
  const auth = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    if (auth.user?.stripeSubscriptionStatus === 'trialing') {
      const trialEnd = new Date(auth.user.stripeSubscriptionTrialEnd * 1000);
      const daysRemaining = Math.ceil((trialEnd - new Date()) / (1000 * 60 * 60 * 24));
      
      if (daysRemaining <= 3) {
        setIsOpen(true);
      }
    }
  }, [auth.user]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white p-8 rounded-lg shadow-xl">
          <h3 className="text-lg font-bold mb-4">Your trial is ending soon!</h3>
          <p className="mb-4">
            To keep access to all features, upgrade to a full subscription now.
          </p>
          <div className="flex justify-end space-x-4">
            <button onClick={() => setIsOpen(false)} className="text-gray-600">
              Remind me later
            </button>
            <Link to="/pricing" className="bg-indigo-600 text-white px-4 py-2 rounded">
              Upgrade Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
} 