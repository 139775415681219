import React from "react";
import { invertedMetrics, getOrdinalSuffix, stats } from "../../util/MetricsConfig";

const PercentileStatusPill = ({ userPercentile, metricName }) => {
  const isInverted = invertedMetrics.includes(metricName);
  
  // Find the metric configuration to check if it's opinionated
  const metricConfig = stats.find(stat => stat.name === metricName);
  const isOpinionated = metricConfig?.opinionated ?? true; // Default to opinionated if not specified
  
  const getStatusColors = (percentile) => {
    if (percentile == null || isNaN(percentile)) {
      return { bg: "bg-gray-100", text: "text-gray-600" };
    }
    
    // For inverted metrics, we invert the color logic but keep the percentile value
    const effectivePercentile = isInverted ? (100 - percentile) : percentile;
    
    if (isOpinionated) {
      // Opinionated metrics use green/yellow/red
      if (effectivePercentile >= 75) {
        return { bg: "bg-green-100", text: "text-green-700" };
      } else if (effectivePercentile >= 40) {
        return { bg: "bg-yellow-100", text: "text-yellow-800" };
      } else {
        return { bg: "bg-red-100", text: "text-red-700" };
      }
    } else {
      // Non-opinionated metrics use neutral colors (blues)
      if (effectivePercentile >= 75) {
        return { bg: "bg-blue-100", text: "text-blue-700" };
      } else if (effectivePercentile >= 40) {
        return { bg: "bg-slate-100", text: "text-slate-700" };
      } else {
        return { bg: "bg-blue-100", text: "text-blue-700" };
      }
    }
  };

  const { bg, text } = getStatusColors(userPercentile);
  const statusText = userPercentile != null && !isNaN(userPercentile)
    ? `${getOrdinalSuffix(Math.round(userPercentile))} percentile`
    : "Unavailable";

  return (
    <span className={`inline-flex items-center gap-x-1.5 rounded-full ${bg} px-2 py-1 text-xs font-medium ${text}`}>
      {statusText}
    </span>
  );
};

export default PercentileStatusPill;
