import React, { useState } from "react";
import FormAlert from "../general/FormAlert";
import AuthForm from "./AuthForm";
import AuthSocial from "./AuthSocial";
import { useRouter } from "../../util/router";
import { useAuth } from "../../util/auth";

function Auth(props) {
  const router = useRouter();
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState(null);

  const handleAuth = async (user) => {
    try {
      if (props.type === "signout") {
        await auth.signout();
        // The redirect will be handled in the signout function
      }
      // Other auth types will be handled by their respective functions
    } catch (error) {
      handleFormAlert({
        type: "error",
        message: error.message,
      });
    }
  };

  const handleFormAlert = (data) => {
    setFormAlert(data);
  };

  return (
    <>
      {formAlert && (
        <div className="mb-4">
          <FormAlert type={formAlert.type} message={formAlert.message} />
        </div>
      )}

      <AuthForm
        type={props.type}
        buttonAction={props.buttonAction}
        onAuth={handleAuth}
        onFormAlert={handleFormAlert}
      />

      {["signup", "signin"].includes(props.type) && (
        <>
          {props.providers && props.providers.length && (
            <AuthSocial
              buttonAction={props.buttonAction}
              providers={props.providers}
              showLastUsed={true}
              onAuth={handleAuth}
              onError={(message) => {
                handleFormAlert({
                  type: "error",
                  message: message,
                });
              }}
            />
          )}
        </>
      )}
    </>
  );
}

export default Auth;
