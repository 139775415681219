import React from "react";
import { useEffect, useState } from "react";
import { LightBulbIcon } from "@heroicons/react/24/outline";
import Button from "../cosmetics/Button";
import { columnMapping, stats, invertedMetrics } from "../../util/MetricsConfig";
import PercentileStatusPill from "./PercentileStatusPill";
import { Link } from "../../util/router";
import { useCurrency } from '../../util/CurrencyContext';

const ResultsDetail = ({
  userMetricsData,
  metricName,
  allMetricsData,
  metricStats,
  metricSampleSize,
  selectedMetric
}) => {
  const { currencySymbol } = useCurrency();
  const [percentile, setPercentile] = useState(null);


  
  // The median is in ranges.mid
  const medianValue = metricStats?.ranges?.mid;

  // Find the human-readable name for the metric
  const metricNamePretty = Object.keys(columnMapping).find(key => columnMapping[key] === metricName);
  // Find the metric details using the human-readable name
  const metricDetail = stats.find(detail => detail.name === metricNamePretty);
  const isCurrencyMetric = metricDetail?.isCurrency || false;

  // Check if metric is inverted
  const isInverted = invertedMetrics.includes(metricNamePretty);

  const formatValue = (value) => {
    if (value === null || value === undefined || isNaN(value)) return 'N/A';
    
    // Format the number first
    let formattedNumber = value >= 1000000 
      ? `${(value / 1000000).toFixed(1)}M`
      : value >= 1000 
      ? `${(value / 1000).toFixed(0)}K`
      : value.toLocaleString();

    // Add currency symbol or unit
    if (isCurrencyMetric) {
      return `${currencySymbol}${formattedNumber}`;
    } else if (metricDetail?.unit) {
      return metricDetail.unitLocation === 'front' 
        ? `${metricDetail.unit}${formattedNumber}`
        : `${formattedNumber}${metricDetail.unit}`;
    }
    
    return formattedNumber;
  };

  useEffect(() => {
    if (metricStats?.percentile) {
      setPercentile(metricStats.percentile);
    }
  }, [metricStats]);

  const buttonClick = () => {
    window.open("https://calendly.com/open-org/peoplemetrics-coaching-call");
  };

  const getUserQuartileLabel = (percentile) => {
    const quartile = Math.ceil(percentile / 25);
    return quartile === 1 ? 'Bottom 25%' :
           quartile === 2 ? '26-50th' :
           quartile === 3 ? '51-75th' :
           'Top 25%';
  };

  return (
    <div className="results-detail pt-6 bg-white">
      <h2 className="text-2xl font-semibold text-gray-900 mb-4">
        Executive Summary
      </h2>
      <p className="text-gray-700 mb-4">
        Here's how your company compares to the startup/scale-up market on:{" "}
        <strong>{metricDetail?.name}</strong>.
      </p>
      <p className="text-gray-700 mb-4">
        Your current {metricDetail?.name} is:{" "}
        <strong>
          {formatValue(userMetricsData[metricName])}
        </strong>
      </p>
      <p className="text-gray-700 mb-4">
        Based on the filters you have selected, this puts you in the{" "}
        <PercentileStatusPill
          userPercentile={isInverted ? (100 - metricStats?.percentile) : metricStats?.percentile}
          metricName={metricDetail?.name}
        />{" "}
        range within the current sample of: <strong>{metricSampleSize}</strong> startups 
        and scale-ups who have contributed data to the {metricNamePretty} benchmark.
      </p>

      <p className="text-gray-700 mb-4">
        The median value in the sample is:{" "}
        <strong>
          {formatValue(medianValue)}
        </strong>
      </p>

      <p className="text-gray-700 mb-4">
        For context, the highest value in the sample is:{" "}
        <strong>
          {formatValue(isInverted ? metricStats?.min : metricStats?.max)}
        </strong>
      </p>

      <p className="text-gray-700 mb-4">
        The lowest value in the sample is:{" "}
        <strong>
          {formatValue(isInverted ? metricStats?.max : metricStats?.min)}
        </strong>
      </p>

      {metricStats?.percentileRanges && (
        <>
          <p className="text-gray-700 mb-4">
            The range for the {isInverted ? '90-100th' : '0-10th'} percentile is between:{" "}
            <strong>
              {isInverted 
                ? `${formatValue(metricStats.percentileRanges[90])} - ${formatValue(metricStats.max)}`
                : `${formatValue(metricStats.percentileRanges[0])} - ${formatValue(metricStats.percentileRanges[10])}`
              }
            </strong>
          </p>
          <p className="text-gray-700 mb-4">
            The value at the 50th percentile is:{" "}
            <strong>{formatValue(metricStats.percentileRanges[50])}</strong>
          </p>
          <p className="text-gray-700 mb-4">
            The range for the {isInverted ? '0-10th' : '90-100th'} percentile is between:{" "}
            <strong>
              {isInverted 
                ? `${formatValue(metricStats.percentileRanges[0])} - ${formatValue(metricStats.percentileRanges[10])}`
                : `${formatValue(metricStats.percentileRanges[90])} - ${formatValue(metricStats.max)}`
              }
            </strong>
          </p>
        </>
      )}

      <p className="text-gray-700 mb-4">
        Your result is based on comparison to other companies based on the filters you have selected, and may change when compared to a different sample.

      </p>
      <p className="text-gray-700 mb-4">
        Use this benchmark to make a compelling case for change, identify areas for improvement, set goals, and
        make data-driven decisions.
      </p>

      <p className="text-gray-700 mb-4">
        To edit your {metricDetail.name} data, or any other metric{" "}
        <Link className="text-bold underline cursor-pointer" to="/edit-metrics">
          click here
        </Link>
        .
      </p>

      {/* Grey callout box */}


      <div className="bg-gray-200 rounded-lg p-4 mt-10">
        {" "}
        {/* Increased the mt-4 to mt-6 */}
        <div className="flex items-center">
          <LightBulbIcon className="w-6 h-6 ml-1" />
          <p className="text-gray-700 font-semibold ml-4">Need some help?</p>
        </div>
        <p className="text-gray-600 mt-2 mb-4">
          Receive a personalized one-on-one guidance session, where our team
          will provided tailored insights and actionable recommendations to help you build the case for change you need.
        </p>
        <Button variant="secondary" onClick={buttonClick}>
          Book Coaching Call
        </Button>
      </div>

      {/* <div className="bg-indigo-200 rounded-lg p-4 mt-6">
        {" "}
        <div className="flex items-center">
          <RocketLaunchIcon className="w-6 h-6 ml-1" />
          <p className="text-gray-700 font-semibold ml-4">Product update</p>
        </div>
        <p className="text-gray-600 mt-2 mb-4">
          We've got a ton of great feedback from early adopters like you on this chart and so are now actively working to enhance it to show a more detailed percentile view. Hope to ship a new version in the next week, thanks for your patience.
        </p>

      </div> */}
    </div>
  );
};

export default ResultsDetail;
