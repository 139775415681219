// App.js
import React from "react";
import "./../styles/global.css";
import "./../util/analytics";
import { AuthProvider, useAuth } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import { Helmet } from "react-helmet";
import { FiltersProvider } from "../util/FiltersContext";
import { MetricsProvider } from "../util/UserMetricsContext";
import { CurrencyProvider } from "../util/CurrencyContext";
import PageRoutes from "./page_routes";

const App = (props) => {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <CurrencyProvider>
          <MetricsProvider>
            <AuthUserWrapper>
              <PageRoutes />
            </AuthUserWrapper>
          </MetricsProvider>
        </CurrencyProvider>
      </AuthProvider>
      <Helmet>
        {/* 100% privacy-first analytics */}
        <script async src="https://scripts.simpleanalyticscdn.com/latest.js"></script>
      </Helmet>
    </QueryClientProvider>
  );
};

const AuthUserWrapper = ({ children }) => {
  const auth = useAuth();
  const userId = auth.user?.uid;

  return (
    <FiltersProvider userId={userId}>
      {children}
    </FiltersProvider>
  );
};

export default App;
