import React from "react";
import { StarIcon, ShieldCheckIcon, ExclamationCircleIcon, SunIcon, BeakerIcon, BoltIcon } from '@heroicons/react/20/solid';
import { calculateMetricStats, columnMapping, invertedMetrics, stats } from '../../util/MetricsConfig';

const getStatus = (percentile, metricName) => {
  // Get the metric configuration
  const metricNamePretty = Object.keys(columnMapping).find(key => columnMapping[key] === metricName);
  const metricConfig = stats.find(stat => stat.name === metricNamePretty);
  const isOpinionated = metricConfig?.opinionated ?? true;
  const isInverted = invertedMetrics.includes(metricNamePretty);

  // Determine the status text and icon based on percentile
  let statusText;
  let Icon;
  const adjustedPercentile = isInverted ? 100 - percentile : percentile;

  if (adjustedPercentile >= 90) {
    statusText = "Top 10%";
    Icon = StarIcon;
  } else if (adjustedPercentile >= 75) {
    statusText = "Top 25%";
    Icon = ShieldCheckIcon;
  } else if (adjustedPercentile >= 50) {
    statusText = "Above median";
    Icon = SunIcon;
  } else if (adjustedPercentile >= 25) {
    statusText = "Bottom 25%";
    Icon = BeakerIcon;
  } else {
    statusText = "Bottom 10%";
    Icon = BoltIcon;
  }

  // For non-opinionated metrics, always use indigo colors
  if (!isOpinionated) {
    return {
      bgColor: "bg-gray-100",
      textColor: "text-gray-600",
      hoverBgColor: "hover:bg-indigo-100",
      hoverTextColor: "hover:text-indigo-700",
      statusText,
      Icon
    };
  }

  // For opinionated metrics, use color scheme based on percentile
  if (adjustedPercentile >= 90) {
    return { 
      bgColor: "bg-gray-100", 
      textColor: "text-gray-600", 
      hoverBgColor: "hover:bg-green-100", 
      hoverTextColor: "hover:text-green-700", 
      statusText, 
      Icon 
    };
  } else if (adjustedPercentile >= 75) {
    return { 
      bgColor: "bg-gray-100", 
      textColor: "text-gray-600", 
      hoverBgColor: "hover:bg-indigo-100", 
      hoverTextColor: "hover:text-indigo-700", 
      statusText, 
      Icon 
    };
  } else if (adjustedPercentile >= 50) {
    return { 
      bgColor: "bg-gray-100", 
      textColor: "text-gray-600", 
      hoverBgColor: "hover:bg-yellow-100", 
      hoverTextColor: "hover:text-yellow-800", 
      statusText, 
      Icon 
    };
  } else if (adjustedPercentile >= 25) {
    return { 
      bgColor: "bg-gray-100", 
      textColor: "text-gray-600", 
      hoverBgColor: "hover:bg-orange-100", 
      hoverTextColor: "hover:text-orange-700", 
      statusText, 
      Icon 
    };
  } else {
    return { 
      bgColor: "bg-gray-100", 
      textColor: "text-gray-600", 
      hoverBgColor: "hover:bg-red-100", 
      hoverTextColor: "hover:text-red-700", 
      statusText, 
      Icon 
    };
  }
};

const StatusPill = ({ userMetricsData, metricName, allMetricsData }) => {
  let defaultBgColor = "bg-gray-100";
  let defaultTextColor = "text-gray-600";
  let statusText = "Unavailable";
  let StatusIcon = ExclamationCircleIcon;
  let hoverBgColor = "hover:bg-gray-200";
  let hoverTextColor = "hover:text-gray-700";

  if (userMetricsData && metricName && allMetricsData) {
    const rawData = allMetricsData
      .map(item => parseFloat(item[metricName]))
      .filter(value => !isNaN(value) && value !== null);
    
    const rawUserScore = parseFloat(userMetricsData[metricName]);
    
    if (!isNaN(rawUserScore)) {
      const stats = calculateMetricStats(rawUserScore, rawData, metricName);
      const status = getStatus(stats.percentile, metricName);
      
      defaultBgColor = status.bgColor;
      defaultTextColor = status.textColor;
      hoverBgColor = status.hoverBgColor;
      hoverTextColor = status.hoverTextColor;
      statusText = status.statusText;
      StatusIcon = status.Icon;
    }
  }

  return (
    <span 
      className={`inline-flex items-center gap-x-1.5 rounded-full ${defaultBgColor} px-2 py-1 text-xs font-medium ${defaultTextColor} ${hoverBgColor} ${hoverTextColor} transition-colors duration-200`}
    >
      <StatusIcon className="h-4 w-4" aria-hidden="true" />
      {statusText}
    </span>
  );
};

export default StatusPill;
