import React, { useEffect, useState, useContext, useMemo, useCallback } from "react";
import { AdjustmentsHorizontalIcon, EyeIcon, WrenchIcon } from "@heroicons/react/20/solid";
import { useMetricsByOwner, fetchStageFilterData, fetchMetricsByOwnerAndPeriod, fetchAverageMetricsByOwnerAndPeriod, loadFilterConfig, loadDashboardConfig, saveDashboardConfig, fetchFilteredDataAndMedians } from "../../util/db";
import { useAuth } from "../../util/auth";
import UserMetricCard from "./UserMetricCard";
import "../../styles/global.css";
import { stats, columnMapping, headcountOptions, options, regionOptions, calculateQuarterlyTrend, calculateUserPercentile, checkForCurrentQuarterMetrics, getCurrentQuarter, invertedMetrics } from "../../util/MetricsConfig";
import PDFGenerationComponent from "../../util/PDFGenerationComponent";
import RefsContext from "../../util/RefsContext";
import Filters from "./Filters";
import { useFilters } from "../../util/FiltersContext";
import { useMetrics } from "../../util/UserMetricsContext";
import { useHistory } from "../../util/router";
import OnboardingPromptModal from "../modals/OnboardingPromptModal";
import AlertPrompt from "./AlertPrompt";
import MetricsDropdown from "./MetricsDropdown"; // Import the new Dropdown component
import NewFeatureFlag from "../general/NewFeatureFlag";
import { ExportDataButton } from './ExportDataButton';
import { useCurrency } from '../../util/CurrencyContext';

export default function UserMetricsDashboard() {
  const auth = useAuth();
  const { metricsData, setMetricsData } = useMetrics();
  const history = useHistory();
  const ownerId = auth.user?.uid;
  const [dataSampleSize, setDataSampleSize] = useState(null);
  const [allMetricsData, setAllMetricsData] = useState([]);
  const [selectedMetricData, setSelectedMetricData] = useState([]);
  const [orgCount, setOrgCount] = useState(null);
  const [showPDF, setShowPDF] = useState(false);
  const [cardRefs, setCardRefs] = useState([]);
  const [peopleCardRefs, setPeopleCardRefs] = useState([]);
  const [talentCardRefs, setTalentCardRefs] = useState([]);
  const [refsReady, setRefsReady] = useState(false);
  const { titleRef, subtitleRef } = useContext(RefsContext);
  const { selectedHeadcount, selectedStage, selectedLocation, selectedTimePeriod } = useFilters();
  const [metricSampleSizes, setMetricSampleSizes] = useState({});
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [hasCurrentQuarterMetrics, setHasCurrentQuarterMetrics] = useState(true);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedQuarter, setSelectedQuarter] = useState(getCurrentQuarter());
  const [previousMetricsState, setPreviousMetricsState] = useState({});
  const [metricCount, setMetricCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filterConfig, setFilterConfig] = useState(null);
  const [hiddenMetrics, setHiddenMetrics] = useState([]); // State to manage hidden metrics

  const { data: metrics, error: metricsError, isFetching } = useMetricsByOwner(ownerId);
  const defaultFilterConfig = {
    selectedHeadcount: headcountOptions.filter(option => option.label === 'All'),
    selectedStage: options.filter(option => option.label === 'All'),
    selectedLocation: regionOptions.filter(option => option.label === 'All'),
    selectedTimePeriod: "Current Quarter"
  };

  const [filteredData, setFilteredData] = useState(null);
  const [medians, setMedians] = useState(null);

  const { convertFromUSD, currencySymbol, selectedCurrency } = useCurrency();

  // Modify convertDataset to be more explicit about currency handling
  const convertDataset = useCallback((dataset) => {
    if (!dataset) return dataset;
    
    return dataset.map(item => {
      const convertedItem = { ...item };
      stats.forEach(metric => {
        const columnName = columnMapping[metric.name];
        // Only convert if we have a value and it's a currency metric
        if (metric.isCurrency && item[columnName] != null) {
          const value = parseFloat(item[columnName]);
          if (!isNaN(value)) {
            // Store both the original USD and converted value
            convertedItem[`${columnName}_usd`] = value;
            convertedItem[columnName] = convertFromUSD(value);
          }
        }
      });
      return convertedItem;
    });
  }, [convertFromUSD]);

  useEffect(() => {
    const fetchFilteredDataAndMediansEffect = async () => {
      if (!filterConfig) return;

      try {
        setIsLoading(true);
        const { data, medians } = await fetchFilteredDataAndMedians(filterConfig);
        setFilteredData(data);
        setMedians(medians);
      } catch (error) {
        console.error("Error fetching filtered data and medians:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFilteredDataAndMediansEffect();
  }, [filterConfig]); // This effect will run whenever filterConfig changes

  useEffect(() => {
    const fetchFilterConfiguration = async () => {
      if (!ownerId) return;
      try {
        const config = await loadFilterConfig(ownerId);
        setFilterConfig(config || defaultFilterConfig);
      } catch (err) {
        console.error("Error fetching filter configuration:", err);
        // Apply default config if there's an error
        setFilterConfig(defaultFilterConfig);
      }
    };

    fetchFilterConfiguration();
  }, [ownerId]);

  useEffect(() => {
    const fetchDashboardConfiguration = async () => {
      if (!ownerId) return;
      try {
        const config = await loadDashboardConfig(ownerId);
        setHiddenMetrics(config?.hiddenMetrics || []);
      } catch (err) {
        console.error("Error fetching dashboard configuration:", err);
        // Apply default config if there's an error
        setHiddenMetrics([]);
      }
    };

    fetchDashboardConfiguration();
  }, [ownerId]);

  // Add effect to handle currency changes
  useEffect(() => {
    // Reconvert all data when currency changes
    if (metricsData.userMetricsData) {
      const reconvertedData = convertDataset([metricsData.userMetricsData]);
      setMetricsData(prevState => ({
        ...prevState,
        userMetricsData: reconvertedData[0]
      }));
    }
    
    if (allMetricsData.length > 0) {
      const reconvertedAllData = convertDataset(allMetricsData.map(item => ({
        ...item,
        // Restore original USD values for reconversion
        ...Object.fromEntries(
          stats
            .filter(stat => stat.isCurrency)
            .map(stat => [columnMapping[stat.name], item[`${columnMapping[stat.name]}_usd`]])
        )
      })));
      setAllMetricsData(reconvertedAllData);
    }
    
    if (previousMetricsState[0]) {
      const reconvertedPrevious = convertDataset([previousMetricsState[0]]);
      setPreviousMetricsState(reconvertedPrevious);
    }
  }, [selectedCurrency, convertDataset]);

  // Modify the main data fetching effect
  useEffect(() => {
    const fetchData = async () => {
      if (!ownerId || !filterConfig) return;

      setIsLoading(true);
      try {
        // Get the appropriate year and quarter based on selectedTimePeriod
        let yearToFetch = selectedYear;
        let quarterToFetch = selectedQuarter;

        switch (selectedTimePeriod) {
          case 'Previous Quarter':
            if (selectedQuarter === 'Q1') {
              yearToFetch = selectedYear - 1;
              quarterToFetch = 'Q4';
            } else {
              quarterToFetch = `Q${parseInt(selectedQuarter.slice(1)) - 1}`;
            }
            break;
          case 'Previous Quarter minus 1':
            if (selectedQuarter === 'Q1') {
              yearToFetch = selectedYear - 1;
              quarterToFetch = 'Q3';
            } else if (selectedQuarter === 'Q2') {
              yearToFetch = selectedYear - 1;
              quarterToFetch = 'Q4';
            } else {
              quarterToFetch = `Q${parseInt(selectedQuarter.slice(1)) - 2}`;
            }
            break;
          // Add other cases as needed
        }

        const [fetchedMetrics, stageFilterData] = await Promise.all([
          fetchMetricsByOwnerAndPeriod(ownerId, yearToFetch, quarterToFetch),
          fetchStageFilterData(
            selectedHeadcount.map(option => option.value),
            selectedStage.map(option => option.value),
            selectedLocation.map(option => option.value)
          ),
        ]);

        const { data, sampleSizes } = stageFilterData;
        const convertedData = convertDataset(data);
        const convertedMetrics = convertDataset(fetchedMetrics);

        setOrgCount(data.length);
        setAllMetricsData(convertedData);
        setMetricSampleSizes(sampleSizes);
        setMetricsData(prevState => ({
          ...prevState,
          orgCount: data.length,
          allMetricsData: convertedData,
          metricSampleSizes: sampleSizes,
          userMetricsData: convertedMetrics.length > 0 ? convertedMetrics[0] : prevState.userMetricsData,
        }));

        // Handle previous metrics with currency conversion
        const previousQuarter = selectedQuarter === "Q1" ? "Q4" : `Q${parseInt(selectedQuarter.slice(1)) - 1}`;
        const previousYear = selectedQuarter === "Q1" ? selectedYear - 1 : selectedYear;
        const fetchedPreviousMetrics = await fetchMetricsByOwnerAndPeriod(ownerId, previousYear, previousQuarter);
        const convertedPreviousMetrics = convertDataset(fetchedPreviousMetrics);
        
        setPreviousMetricsState(convertedPreviousMetrics);
        setHasCurrentQuarterMetrics(true);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [ownerId, selectedYear, selectedQuarter, selectedHeadcount, selectedStage, selectedLocation, filterConfig, selectedTimePeriod, convertDataset]); // Add selectedTimePeriod to dependencies

  useEffect(() => {
    const checkRefs = () => {
      if (titleRef.current && subtitleRef.current) {
        setRefsReady(true);
      } else {
        setRefsReady(false);
      }
    };

    checkRefs();
  }, [titleRef, subtitleRef]);

  useEffect(() => {
    setPeopleCardRefs(metricsData.userMetricsData ? stats.filter(metric => metric.metricType === 'People').map(() => React.createRef()) : []);
  }, [metricsData.userMetricsData]);

  useEffect(() => {
    setTalentCardRefs(metricsData.userMetricsData ? stats.filter(metric => metric.metricType === 'Talent').map(() => React.createRef()) : []);
  }, [metricsData.userMetricsData]);

  useEffect(() => {
    setCardRefs(metricsData.userMetricsData ? stats.map(() => React.createRef()) : []);
  }, [metricsData.userMetricsData]);

  function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }

    return true;
  }

  useEffect(() => {
    const mergedCardRefs = metricsData.userMetricsData ? stats.map((_, index) => cardRefs[index]) : [];

    if (!arraysEqual(mergedCardRefs, cardRefs)) {
      setCardRefs(mergedCardRefs);
    }
  }, [metricsData.userMetricsData, cardRefs]);

  const handleGeneratePDFClick = () => {
    if (refsReady) {
      setShowPDF(true);
    }
  };

  const fetchDataByTimePeriod = async () => {
    if (!ownerId) return;
    setIsLoading(true);
    try {
      const timePeriodMapping = {
        'Current Quarter': selectedQuarter,
        'Previous Quarter': selectedQuarter === 'Q1' ? 'Q4' : `Q${parseInt(selectedQuarter.slice(1)) - 1}`,
        'Previous Quarter minus 1': selectedQuarter === 'Q1' ? 'Q3' : `Q${parseInt(selectedQuarter.slice(1)) - 2}`,
        'Previous Quarter minus 2': selectedQuarter === 'Q1' ? 'Q2' : `Q${parseInt(selectedQuarter.slice(1)) - 3}`,
        'Average of last 6 months': 'last_6_months',
        'Average of last 12 months': 'last_12_months'
      };

      const period = timePeriodMapping[selectedTimePeriod];
      let fetchedMetrics;

      if (period === 'last_6_months' || period === 'last_12_months') {
        fetchedMetrics = await fetchAverageMetricsByOwnerAndPeriod(ownerId, period);
      } else {
        fetchedMetrics = await fetchMetricsByOwnerAndPeriod(ownerId, selectedYear, period);
      }


      setMetricsData(prevState => ({
        ...prevState,
        userMetricsData: fetchedMetrics.length > 0 ? fetchedMetrics[0] : prevState.userMetricsData,
      }));
    } catch (error) {
      console.error("Error fetching data by time period:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataByTimePeriod();
  }, [selectedTimePeriod]);

  const updatedStats = useMemo(() => {
    if (!metricsData.userMetricsData) return [];

    const userMetricsData = metricsData.userMetricsData;
    const data = allMetricsData;

    return stats.map((metric) => {
      const columnName = columnMapping[metric.name];
      const usdColumnName = `${columnName}_usd`;
      
      // Get values, preferring USD stored values for currency metrics
      const rawValue = metric.isCurrency && userMetricsData[usdColumnName] != null
        ? userMetricsData[usdColumnName]
        : parseFloat(userMetricsData[columnName]);
      
      const rawPreviousValue = metric.isCurrency && previousMetricsState[0]?.[usdColumnName] != null
        ? previousMetricsState[0][usdColumnName]
        : parseFloat(previousMetricsState[0]?.[columnName]);

      // Convert currency values
      const metricValue = metric.isCurrency && !isNaN(rawValue)
        ? convertFromUSD(rawValue)
        : rawValue;

      const previousMetricValue = metric.isCurrency && !isNaN(rawPreviousValue)
        ? convertFromUSD(rawPreviousValue)
        : rawPreviousValue;

      // Rest of the calculation remains the same
      const validMetricValue = !isNaN(metricValue) ? metricValue : null;
      const validPreviousMetricValue = !isNaN(previousMetricValue) ? previousMetricValue : null;

      // Convert all data points for percentile calculation if it's a currency metric
      const dataForPercentile = data.map(dataItem => {
        const value = parseFloat(dataItem[columnName]);
        return metric.isCurrency ? convertFromUSD(value) : value;
      });

      // Calculate percentile taking into account inverted metrics
      const isInverted = invertedMetrics.includes(metric.name);
      const percentile = validMetricValue !== null
        ? isInverted 
          ? 100 - calculateUserPercentile(validMetricValue, dataForPercentile)
          : calculateUserPercentile(validMetricValue, dataForPercentile)
        : null;

      const quarterlyTrend = validMetricValue !== null && validPreviousMetricValue !== null
        ? calculateQuarterlyTrend(validMetricValue, validPreviousMetricValue)
        : null;

      return {
        ...metric,
        db_col: columnName,
        stat: validMetricValue !== null ? validMetricValue : 'N/A',
        stat_usd: metric.isCurrency ? rawValue : undefined, // Store USD value for reference
        percentile,
        sampleSize: metricSampleSizes[columnName],
        quarterlyTrend,
        opinionated: metric.opinionated,
        isCurrency: metric.isCurrency
      };
    });
  }, [metricsData.userMetricsData, allMetricsData, previousMetricsState, metricSampleSizes, selectedTimePeriod, convertFromUSD]);

  const toggleMetricVisibility = (metricName) => {
    setHiddenMetrics(prev => {
      const updatedHiddenMetrics = prev.includes(metricName)
        ? prev.filter(name => name !== metricName)
        : [...prev, metricName];

      saveDashboardConfig(ownerId, { hiddenMetrics: updatedHiddenMetrics });
      return updatedHiddenMetrics;
    });
  };

  const [historicalMetrics, setHistoricalMetrics] = useState([]);
  const [isHistoricalDataLoading, setIsHistoricalDataLoading] = useState(true);

  useEffect(() => {
    const fetchHistoricalData = async () => {
      if (!ownerId) return;

      setIsHistoricalDataLoading(true);
      try {
        const currentYear = new Date().getFullYear();
        const allMetrics = [];

        for (let year = currentYear; year > currentYear - 5; year--) {
          for (let quarter = 1; quarter <= 4; quarter++) {
            const data = await fetchMetricsByOwnerAndPeriod(ownerId, year, `Q${quarter}`);
            if (data && data.length > 0) {
              allMetrics.push(...data);
            }
          }
        }

        setHistoricalMetrics(allMetrics);
      } catch (error) {
        console.error("Error fetching historical metrics:", error);
      } finally {
        setIsHistoricalDataLoading(false);
      }
    };

    fetchHistoricalData();
  }, [ownerId]);

  // When preparing metrics for display, we need to convert the values
  const prepareMetricForDisplay = useCallback((metric) => {
    if (!metric) return metric;
    
    const metricConfig = stats.find(stat => stat.name === metric.name);
    const isCurrencyMetric = metricConfig?.isCurrency || false;
    
    return {
      ...metric,
      stat: isCurrencyMetric 
        ? convertFromUSD(parseFloat(metric.stat), metric.name).toFixed(1)
        : metric.stat
    };
  }, [convertFromUSD]);

  return (
    <div className="w-full">
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-2">
          <AdjustmentsHorizontalIcon className="h-4 w-4 text-gray-700" aria-hidden="true" />
          <h3 className="text-m font-bold text-gray-700">Filters</h3>
        </div>
        
        {filterConfig && (
          <Filters
            headcountOptions={headcountOptions}
            stageOptions={options}
            locationOptions={regionOptions}
            initialFilterConfig={filterConfig}
          />
        )}
        
        <div className="text-xs font-xs text-gray-500">
          <p>Compared to <strong>{orgCount}</strong> startups at your size and stage</p>
        </div>
        
        <div className="flex items-center space-x-2">
          <WrenchIcon className="h-4 w-4 text-gray-700" aria-hidden="true" />
          <h3 className="text-m font-bold text-gray-700">Configure</h3>
        </div>
        
        <div className="flex items-center justify-between">
          <MetricsDropdown hiddenMetrics={hiddenMetrics} toggleMetricVisibility={toggleMetricVisibility} />
          <ExportDataButton
            userMetricsData={historicalMetrics}
            hiddenMetrics={hiddenMetrics}
            filteredData={filteredData}
            medians={medians}
            isLoading={isLoading || isHistoricalDataLoading}
          />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex items-center space-x-8 mt-8 mb-6">
          <h2 className="text-xl font-semibold text-gray-600">People Metrics</h2>
          {!hasCurrentQuarterMetrics && (
            <AlertPrompt className="h-4 w-4 text-gray-700" aria-hidden="true" />
          )}
        </div>

        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
          {updatedStats.length > 0 && updatedStats.filter(metric => metric.metricType === 'People' && !hiddenMetrics.includes(metric.name)).map((metric, index) => (
            <UserMetricCard
              key={metric.id}
              metric={metric}
              selectedStage={selectedStage}
              selectedHeadcount={selectedHeadcount}
              selectedLocation={selectedLocation}
              userPercentile={metric.percentile}
              ref={peopleCardRefs[index] ? peopleCardRefs[index] : null}
              sampleSize={metric.sampleSize}
              setSelectedMetricData={setSelectedMetricData}
              quarterlyTrend={metric.quarterlyTrend}
              isOpinionated={metric.opinionated} // Pass the opinionated flag
            />
          ))}
        </div>
      </div>

      <div className="mt-8">
        <div className="flex items-center space-x-8 mt-8 mb-6">
          <h2 className="text-xl font-semibold text-gray-600">Talent Metrics</h2>
          {!hasCurrentQuarterMetrics && (
            <AlertPrompt className="h-4 w-4 text-gray-700" aria-hidden="true" />
          )}
        </div>
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
          {updatedStats.length > 0 && updatedStats.filter(metric => metric.metricType === 'Talent' && !hiddenMetrics.includes(metric.name)).map((metric, index) => (
            <UserMetricCard
              key={metric.id}
              metric={metric}
              selectedStage={selectedStage}
              selectedHeadcount={selectedHeadcount}
              selectedLocation={selectedLocation}
              userPercentile={metric.percentile}
              ref={talentCardRefs[index] ? talentCardRefs[index] : null}
              sampleSize={metric.sampleSize}
              setSelectedMetricData={setSelectedMetricData}
              quarterlyTrend={metric.quarterlyTrend}
              isOpinionated={metric.opinionated} // Pass the opinionated flag
            />
          ))}
        </div>
      </div>

      {updatedStats.length === 0 && metrics && metrics.length === 0 && !isFetching ? (
        <OnboardingPromptModal
          isOpen={true}
          onClose={() => setShowOnboardingModal(false)}
        />
      ) : updatedStats.length === 0 ? (
        <p> </p>
      ) : null}
      {metricsError && <p>Error fetching your metrics: {metricsError.message}</p>}

      {showPDF && (
        <PDFGenerationComponent
          triggerDownload={showPDF}
          onGenerated={() => setShowPDF(false)}
          titleRef={titleRef}
          subtitleRef={subtitleRef}
          cardRefs={cardRefs}
          peopleCardRefs={peopleCardRefs}
          talentCardRefs={talentCardRefs}
        />
      )}
    </div>
  );
}
