import React from 'react';
import { useAuth } from '../util/auth';
import { Link } from '../util/router';

export default function TrialBanner() {
  const auth = useAuth();
  
  // Don't show banner for paying users or users without onboarding/trial
  if (
    !auth.user?.onboardingCompletedAt || 
    !auth.user?.trial_end ||
    auth.user?.stripeSubscriptionStatus === 'active'
  ) {
    return null;
  }
  
  const trialEnd = new Date(auth.user.trial_end);
  const now = new Date();
  
  // Don't show banner if trial has ended
  if (trialEnd <= now) {
    return null;
  }
  
  const daysRemaining = Math.max(0, Math.ceil((trialEnd - now) / (1000 * 60 * 60 * 24)));
  
  return (
    <div className="relative overflow-hidden bg-gradient-to-r from-indigo-600 via-indigo-500 to-purple-500">
      {/* Animated shine overlay */}
      <div 
        className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent"
        style={{
          animation: 'shimmer 4s linear infinite',
          '@keyframes shimmer': {
            '0%': {
              transform: 'translateX(-100%)',
            },
            '100%': {
              transform: 'translateX(100%)',
            },
          },
        }}
      />
      
      {/* Content */}
      <div className="relative max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="font-medium text-white text-sm sm:text-base drop-shadow-sm">
            ✨ {daysRemaining} {daysRemaining === 1 ? 'day' : 'days'} remaining in your free trial.{' '}
            <Link 
              to="/pricing" 
              className="font-bold animate-rainbow-text-initial hover:animate-rainbow-text transition-all duration-200 inline-flex items-center"
            >
              Upgrade now 
              <span className="ml-1 text-white/90">→</span>
            </Link>
          </p>
        </div>
      </div>

      {/* Add the rainbow animation styles */}
      <style>
        {`
          @keyframes rainbow-text {
            0% {
              background-position: -100%;
            }
            100% {
              background-position: 200%;
            }
          }
          
          .animate-rainbow-text-initial {
            background: linear-gradient(
              to right,
              #fff 20%,
              #ff9ecd 40%,
              #baffc9 60%,
              #bae1ff 80%,
              #fff 100%
            );
            background-size: 200% auto;
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            animation: rainbow-text 2s linear;
            animation-fill-mode: forwards;
          }

          .hover\\:animate-rainbow-text:hover {
            background: linear-gradient(
              to right,
              #fff 20%,
              #ff9ecd 40%,
              #baffc9 60%,
              #bae1ff 80%,
              #fff 100%
            );
            background-size: 200% auto;
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            animation: rainbow-text 2s linear infinite;
          }
        `}
      </style>
    </div>
  );
} 