import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../cosmetics/Button';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../util/auth';

export default function VideoModal({ isOpen, onClose, title, message, videoUrl }) {
    const auth = useAuth();
    const history = useHistory();

    const handleTrialClick = () => {
        if (auth.user) {
            if (!auth.user.onboardingCompletedAt) {
                history.push('/onboarding');
            } else {
                history.push('/dashboard');
            }
        } else {
            history.push('/auth/signup');
        }
        onClose();
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog 
                as="div" 
                className="fixed inset-0 z-50 overflow-y-auto" 
                onClose={onClose}
            >
                <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-4xl sm:align-middle">
                            <div className="bg-white">
                                {title && (
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 px-4 pt-5 sm:px-6">
                                        {title}
                                    </Dialog.Title>
                                )}
                                {message && (
                                    <p className="text-sm text-gray-500 px-4 pt-2 sm:px-6">
                                        {message}
                                    </p>
                                )}
                                <div className="mt-3 sm:mt-4">
                                    <div className="relative pb-[56.25%] h-0 overflow-hidden max-w-full">
                                        <iframe
                                            className="absolute top-0 left-0 w-full h-full"
                                            src={videoUrl}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            title="Embedded youtube"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2">
                                <Button 
                                    variant="gradient" 
                                    onClick={handleTrialClick}
                                    className="w-full sm:w-auto px-4 sm:px-8 py-2 sm:py-3 mb-2 sm:mb-0"
                                >
                                    🎉 Start 14-day free trial
                                </Button>
                                <Button
                                    variant="outline"
                                    onClick={onClose}
                                    className="w-full sm:w-auto px-4 sm:px-8 py-2 sm:py-3"
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
