import md5 from 'md5'; // npm install md5
import supabase from './supabase';

const MAILCHIMP_API_KEY = process.env.MAILCHIMP_API_KEY;
const MAILCHIMP_SERVER = process.env.MAILCHIMP_SERVER;
const MAILCHIMP_LIST_ID = process.env.MAILCHIMP_LIST_ID;

export async function updateMailchimpContact(uid, updates) {
  try {
    // Ensure merge_fields values are strings
    if (updates.merge_fields) {
      Object.keys(updates.merge_fields).forEach(key => {
        if (updates.merge_fields[key] !== null && updates.merge_fields[key] !== undefined) {
          updates.merge_fields[key] = String(updates.merge_fields[key]);
        }
      });
    }

    // console.log('🚀 Starting Mailchimp contact update:', {
    //   uid,
    //   updates,
    //   merge_fields_types: updates.merge_fields && Object.entries(updates.merge_fields)
    //     .map(([key, value]) => `${key}: ${typeof value}`)
    // });

    const response = await fetch('/api/update-mailchimp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        uid, 
        updates: {
          ...updates,
          merge_fields: updates.merge_fields || {},
          tags: updates.tags || []
        }
      })
    });

    // console.log('📨 Mailchimp API response status:', response.status);

    if (!response.ok) {
      const errorData = await response.json();
      // console.error('❌ Mailchimp API error:', errorData);
      throw new Error(errorData.error || 'Failed to update Mailchimp');
    }

    const data = await response.json();
    // console.log('✅ Mailchimp update successful:', data);
    return data;
  } catch (error) {
    // console.error('💥 Error updating Mailchimp contact:', {
    //   message: error.message,
    //   stack: error.stack,
    //   updates
    // });
    throw error;
  }
}

export async function updateUserTag(uid, tag, action = 'add') {
  return updateMailchimpContact(uid, {
    tags: [{ name: tag, status: action === 'add' ? 'active' : 'inactive' }]
  });
} 