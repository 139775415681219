import Logo from '../../images/transparent-logo.png'
import productImage from '../../images/screenshots/chart-image-people-team-percentage.png'
import PricingSection from '../general/PricingSection'
import { useAuth } from '../../util/auth'

export default function WaitlistWelcome() {
  const auth = useAuth();

  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
        {/* <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <div className="flex items-center">
                  <img
                    className="h-11"
                    src={Logo}
                    alt="Your Company"
                  />
                  <h2 className="ml-4 font-medium bold">peoplemetrics.fyi</h2>
                </div>
                <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  Welcome To The Benchmarking Tool For Startup People Leaders.
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Get instant access to benchmarking data from over 600+ startups and scaleups.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl">
              <div className="bg-indigo-500 [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                  aria-hidden="true"
                />
                <div className="relative px-6 pt-4 sm:pt-4 md:pl-16 md:pr-0"> 
                  <div className="mx-auto max-w-2xl md:mx-0 md:max-w-none">
                    <div className="w-screen overflow-hidden rounded-tl-xl">
                      <div className="-mb-px bg-transparent flex text-sm font-medium leading-6">
                        <img
                          src={productImage}
                          alt="Product screenshot"
                          className="w-1/2 h-full object-cover"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
      
      <PricingSection
        size="md"
        title="Choose Your Plan"
        subtitle="Start benchmarking your metrics today"
      />
    </div>
  )
}
