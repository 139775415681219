import clsx from 'clsx'

export default function Container({ className, small, ...props }) {
  return (
    <div
      className={clsx(
        'mx-auto w-full', // Full width on mobile
        small ? 'max-w-5xl' : 'max-w-7xl', // Different max widths based on need
        'px-4 sm:px-6 lg:px-8', // Responsive padding
        'relative', // For positioning context
        className
      )}
      {...props}
    />
  )
}
