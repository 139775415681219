import React, { useState, useEffect, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Cell } from 'recharts';
import { columnMapping, invertedMetrics, stats, calculateMetricStats, calculateUserPercentile } from '../../util/MetricsConfig';
import { useCurrency } from '../../util/CurrencyContext';
import { useMetrics } from '../../util/UserMetricsContext';

const brandColors = {
  purple: "#7C3AED",
  lightPurple: "#A78BFA",
  indigo: {
    50: '#EEF2FF',
    100: '#E0E7FF',
    200: '#C7D2FE',
    300: '#A5B4FC',
    400: '#818CF8',
    500: '#6366F1',
    600: '#4F46E5',
    700: '#4338CA',
  },
  gradient: {
    start: '#F5F3FF',
    end: '#7C3AED',
  }
};

const getOrdinalSuffix = (n) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const HistogramChart = ({ userMetricsData, metricName, metricsData, onMinMaxChange, onUserPercentileChange }) => {
  const { currencySymbol } = useCurrency();
  const [chartData, setChartData] = useState([]);
  const [userScore, setUserScore] = useState(null);
  const [userPercentile, setUserPercentile] = useState(null);
  const [selectedQuartile, setSelectedQuartile] = useState(null);
  const [quartileRanges, setQuartileRanges] = useState([]);

  const metricNameOriginal = useMemo(() => 
    Object.keys(columnMapping).find(key => columnMapping[key] === metricName),
    [metricName]
  );
  
  const metricConfig = useMemo(() => 
    stats.find(stat => stat.name === metricNameOriginal),
    [metricNameOriginal]
  );

  const isCurrencyMetric = metricConfig?.isCurrency || false;

  const formatAxisValue = (value) => {
    if (isCurrencyMetric) {
      // For currency, use K/M notation with correct currency symbol
      if (value >= 1000000) {
        return `${currencySymbol}${(value / 1000000).toFixed(1)}M`;
      }
      if (value >= 1000) {
        return `${currencySymbol}${(value / 1000).toFixed(0)}K`;
      }
      return `${currencySymbol}${value.toFixed(0)}`;
    }
    
    // For non-currency values
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    }
    if (value >= 1000) {
      return `${(value / 1000).toFixed(0)}K`;
    }
    return value.toFixed(0);
  };

  const calculateQuartile = (value, sortedData) => {
    // Find the position of this value in the sorted data
    const position = sortedData.filter(v => v <= value).length;
    // Calculate the percentile
    const percentile = (position / sortedData.length) * 100;
    // Return quartile (1-4)
    return Math.min(4, Math.ceil(percentile / 25));
  };

  useEffect(() => {
    if (!metricsData || !metricName || !userMetricsData) return;

    // Get valid data and user score
    const validData = metricsData
      .map(item => parseFloat(item[metricName]))
      .filter(value => value != null && !isNaN(value))
      .sort((a, b) => a - b); // Sort for percentile calculations

    if (validData.length === 0) return;

    const rawUserScore = parseFloat(userMetricsData[metricName]);
    const validUserScore = !isNaN(rawUserScore) ? rawUserScore : null;
    setUserScore(validUserScore);

    // Calculate percentile first
    if (validUserScore !== null && validData.length > 0) {
      const percentile = calculateUserPercentile(validUserScore, validData);
      setUserPercentile(percentile);
      onUserPercentileChange?.(percentile);
    }

    // Calculate histogram bins
    const min = Math.min(...validData);
    const max = Math.max(...validData);
    const binCount = 20;
    const binSize = (max - min) / binCount;

    const bins = Array.from({ length: binCount }, (_, i) => {
      const binStart = min + (i * binSize);
      const binEnd = binStart + binSize;
      const count = validData.filter(v => v >= binStart && v < binEnd).length;
      
      // Calculate quartile based on bin position relative to the dataset
      const binMedian = (binStart + binEnd) / 2;
      const position = validData.filter(v => v <= binMedian).length;
      const quartile = Math.ceil((position / validData.length) * 4);
      
      return {
        binStart,
        binEnd,
        binRange: `${formatValue(binStart)} - ${formatValue(binEnd)}`,
        count,
        isUserBin: validUserScore !== null && validUserScore >= binStart && validUserScore < binEnd,
        quartile,
        userValue: validUserScore // Add user value to bin data
      };
    });

    setChartData(bins);
    
    if (min !== Infinity && max !== -Infinity) {
      onMinMaxChange?.(min, max);
    }

  }, [metricsData, metricName, userMetricsData]);

  const formatValue = (value) => {
    if (!value && value !== 0) return 'N/A';
    
    // Format the number first
    let formattedNumber = value >= 1000000 
      ? `${(value / 1000000).toFixed(1)}M`
      : value >= 1000 
      ? `${(value / 1000).toFixed(0)}K`
      : value.toLocaleString();

    // Handle currency and unit formatting
    if (isCurrencyMetric) {
      return `${currencySymbol}${formattedNumber}`;
    } else if (metricConfig?.unit) {
      return metricConfig.unitLocation === 'front' 
        ? `${metricConfig.unit}${formattedNumber}`
        : `${formattedNumber}${metricConfig.unit}`;
    }
    
    return formattedNumber;
  };

  const getQuartileColor = (quartile, isUserBin) => {
    if (isUserBin) return brandColors.purple;
    return brandColors.indigo[(quartile * 100) + 200];
  };

  const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload?.length) return null;
    
    const data = payload[0].payload;
    const quartileLabels = {
      1: 'Bottom Quartile (0-25th)',
      2: 'Lower Middle (26-50th)',
      3: 'Upper Middle (51-75th)',
      4: 'Top Quartile (76-100th)'
    };

    return (
      <div className="bg-white p-3 rounded-lg shadow-lg border border-indigo-100">
        {data.isUserBin && userScore !== null && (
          <div className="mb-2 pb-2 border-b border-gray-200">
            <div className="flex items-center space-x-2">
              <div className="w-2 h-2 rounded-full bg-purple-600" />
              <span className="text-sm text-gray-900 font-medium">
                Your value: {formatValue(userScore)}
              </span>
            </div>
          </div>
        )}
        
        <div className="space-y-2">
          <div className="flex items-center space-x-1.5">
            <div 
              className="w-2 h-2 rounded-full"
              style={{ backgroundColor: getQuartileColor(data.quartile, false) }}
            />
            <span className="text-sm text-gray-600">{quartileLabels[data.quartile]}</span>
          </div>
          <span className="text-sm font-medium text-gray-900">
            {data.binRange}
          </span>
        </div>
      </div>
    );
  };

  const StatisticPill = ({ label, value, color }) => (
    <div className="inline-flex items-center px-4 py-2 bg-indigo-50/70 rounded-xl mr-4 border border-indigo-100 shadow-sm hover:shadow-md transition-all duration-200">
      <span className="text-sm text-gray-600 mr-2 font-medium">{label}</span>
      <span className="text-sm font-bold" style={{ color }}>{value}</span>
    </div>
  );

  // Calculate quartile ranges
  useEffect(() => {
    if (!metricsData || !metricName) return;
    
    const values = metricsData
      .map(item => parseFloat(item[metricName]))
      .filter(v => !isNaN(v))
      .sort((a, b) => a - b);
      
    const getQuartileValue = (percentile) => {
      const index = Math.floor((percentile / 100) * (values.length - 1));
      return values[index];
    };

    const ranges = [
      { q: 1, min: values[0], max: getQuartileValue(25) },
      { q: 2, min: getQuartileValue(25), max: getQuartileValue(50) },
      { q: 3, min: getQuartileValue(50), max: getQuartileValue(75) },
      { q: 4, min: getQuartileValue(75), max: values[values.length - 1] }
    ];
    
    setQuartileRanges(ranges);
  }, [metricsData, metricName]);

  const isInSelectedQuartile = (binStart, binEnd) => {
    if (!selectedQuartile) return true;
    const range = quartileRanges.find(r => r.q === selectedQuartile);
    if (!range) return false;

    // For middle quartiles, check if the bin overlaps with the quartile range
    if (selectedQuartile === 2 || selectedQuartile === 3) {
      return (binStart <= range.max && binEnd >= range.min);
    }
    
    // For first quartile, check if bin start is within range
    if (selectedQuartile === 1) {
      return binStart <= range.max;
    }
    
    // For last quartile, check if bin end is within range
    if (selectedQuartile === 4) {
      return binEnd >= range.min;
    }

    return false;
  };

  const quartileLabels = {
    1: 'Bottom Quartile (0-25th)',
    2: 'Lower Middle (26-50th)',
    3: 'Upper Middle (51-75th)',
    4: 'Top Quartile (76-100th)'
  };

  const shortQuartileLabels = {
    1: 'Q1 (0-25th)',
    2: 'Q2 (26-50th)',
    3: 'Q3 (51-75th)',
    4: 'Q4 (76-100th)'
  };

  const getUserQuartileLabel = (percentile) => {
    const quartile = Math.ceil(percentile / 25);
    return quartileLabels[quartile];
  };

  return (
    <div className="bg-white rounded-2xl shadow-xl p-8 border border-gray-100">
      <div className="mb-8">
        <h2 className="text-xl font-bold text-gray-900 mb-2">Distribution Analysis</h2>
        
        {/* Enhanced Key Statistics Section */}
        <div className="mt-4 mb-6 flex flex-wrap gap-y-3">
          <StatisticPill 
            label="Your Value" 
            value={formatValue(userScore)}
            color={brandColors.purple}
          />
          <StatisticPill 
            label="Your Quartile" 
            value={getUserQuartileLabel(userPercentile)}
            color={brandColors.indigo[Math.ceil(userPercentile / 25) * 100 + 200]}
          />
          <StatisticPill 
            label="Percentile" 
            value={`${getOrdinalSuffix(Math.round(userPercentile))}`}
            color={brandColors.indigo[600]}
          />
        </div>

        {/* Enhanced Distribution Summary */}
        <p className="text-sm leading-relaxed text-gray-600 bg-gray-50 p-4 rounded-xl border border-gray-100">
          Your {metricNameOriginal.toLowerCase()} is higher than {Math.round(userPercentile)}% of companies 
          in the dataset{userScore > getMedian(metricsData, metricName) 
            ? `, placing you above the median`
            : `, placing you below the median`}.
        </p>
      </div>

      {/* Update chart styling */}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart 
          data={chartData} 
          margin={{ top: 60, right: 30, left: 40, bottom: 60 }}
          barCategoryGap={4}
        >
          <defs>
            {/* Gradient for user's bar */}
            <linearGradient id="userGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#9F7AEA" />
              <stop offset="100%" stopColor="#7C3AED" />
            </linearGradient>
            {/* Gradient for Q1 */}
            <linearGradient id="q1Gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={brandColors.indigo[200]} />
              <stop offset="100%" stopColor={brandColors.indigo[300]} />
            </linearGradient>
            {/* Gradient for Q2 */}
            <linearGradient id="q2Gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={brandColors.indigo[300]} />
              <stop offset="100%" stopColor={brandColors.indigo[400]} />
            </linearGradient>
            {/* Gradient for Q3 */}
            <linearGradient id="q3Gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={brandColors.indigo[400]} />
              <stop offset="100%" stopColor={brandColors.indigo[500]} />
            </linearGradient>
            {/* Gradient for Q4 */}
            <linearGradient id="q4Gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={brandColors.indigo[500]} />
              <stop offset="100%" stopColor={brandColors.indigo[600]} />
            </linearGradient>
          </defs>

          <CartesianGrid 
            strokeDasharray="3 3" 
            vertical={false} 
            stroke="#E5E7EB" 
            opacity={0.5} 
          />
          <XAxis 
            dataKey="binRange" 
            angle={-45}
            textAnchor="end"
            height={80}
            interval={0}
            tick={{ 
              fontSize: 11,
              fill: '#6B7280',
              fontWeight: 500
            }}
            tickMargin={10}
            axisLine={{ stroke: '#E5E7EB' }}
            tickLine={{ stroke: '#E5E7EB' }}
          />
          <YAxis 
            label={{ 
              value: 'Number of Companies', 
              angle: -90, 
              position: 'insideLeft',
              offset: 0,
              style: { textAnchor: 'middle', fill: '#4B5563', fontSize: 12, fontWeight: 500 }
            }}
            tick={{ fontSize: 12, fill: '#6B7280' }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar 
            dataKey="count" 
            radius={[8, 8, 0, 0]}
            maxBarSize={45}
          >
            {chartData.map((entry, index) => (
              <Cell 
                key={`cell-${index}`}
                fill={entry.isUserBin 
                  ? 'url(#userGradient)' 
                  : `url(#q${entry.quartile}Gradient)`}
                opacity={isInSelectedQuartile(entry.binStart, entry.binEnd) ? 1 : 0.15}
                style={{
                  filter: entry.isUserBin ? 'drop-shadow(0 4px 6px rgba(124, 58, 237, 0.1))' : 'none',
                  transition: 'opacity 0.3s ease'
                }}
              />
            ))}
          </Bar>
          
          {chartData.find(d => d.isUserBin) && (
            <ReferenceLine
              x={chartData.find(d => d.isUserBin)?.binRange}
              stroke="#7C3AED"
              strokeWidth={2}
              strokeDasharray="3 3"
              label={{
                position: 'top',
                fill: '#7C3AED',
                fontSize: 12,
                fontWeight: 600,
                value: `Your ${metricNameOriginal}`,
              }}
              style={{
                filter: 'drop-shadow(0 2px 4px rgba(124, 58, 237, 0.1))'
              }}
            />
          )}
        </BarChart>
      </ResponsiveContainer>

      {/* Enhanced Quartile Legend */}
      <div className="mt-8 grid grid-cols-4 gap-6">
        {quartileRanges.map(({ q, min, max }) => {
          const isUserQuartile = Math.ceil(userPercentile / 25) === q;
          return (
            <div 
              key={q}
              onClick={() => setSelectedQuartile(selectedQuartile === q ? null : q)}
              className={`p-4 rounded-xl border transition-all duration-300 transform hover:scale-105 ${
                selectedQuartile === q 
                  ? 'border-indigo-500 bg-gradient-to-b from-indigo-50 to-white shadow-md' 
                  : isUserQuartile 
                    ? 'border-indigo-300 bg-gradient-to-b from-indigo-50/50 to-white shadow-sm' 
                    : 'border-gray-200 hover:border-indigo-200 hover:shadow-sm'
              }`}
              style={{
                background: isUserQuartile 
                  ? `linear-gradient(to bottom, ${brandColors.indigo[50]}80, white)`
                  : undefined
              }}
            >
              <div className="flex items-center mb-2">
                <div 
                  className="w-3 h-3 rounded mr-2"
                  style={{ backgroundColor: brandColors.indigo[q * 100 + 200] }}
                />
                <span className="text-sm font-medium text-gray-700">
                  {quartileLabels[q]}
                </span>
              </div>
              <div className="space-y-1 text-xs">
                <div className="flex justify-between">
                  <span className="text-gray-500">From:</span>
                  <span className="font-medium text-gray-900">{formatValue(min)}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-500">To:</span>
                  <span className="font-medium text-gray-900">{formatValue(max)}</span>
                </div>
              </div>
              {isUserQuartile && (
                <div className="mt-2 text-xs text-indigo-600 font-medium">
                  Your quartile
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Helper function to calculate median
const getMedian = (data, metricName) => {
  const sortedValues = data
    .map(item => parseFloat(item[metricName]))
    .filter(v => !isNaN(v))
    .sort((a, b) => a - b);
  const mid = Math.floor(sortedValues.length / 2);
  return sortedValues.length % 2 === 0
    ? (sortedValues[mid - 1] + sortedValues[mid]) / 2
    : sortedValues[mid];
};

export default HistogramChart;


