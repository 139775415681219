import Meta from "../components/settings/Meta";
import { useAuth, requireAuth } from "./../util/auth";
import { useRouter } from "./../util/router";
import { WelcomeLayout } from "../components/onboarding/WelcomeLayout";
import Button from "../components/cosmetics/Button";
import { Link } from "react-router-dom";

function WelcomePage(props) {
    const router = useRouter();
    const auth = useAuth();

    return (
        <>
            <WelcomeLayout>
                <Meta title="Welcome" />
                <div className="mt-10 flex flex-col items-center">
                    <div className="flex flex-col items-center">
                        <Button 
                            variant="primary"
                            component={Link}
                            to={auth.user ? "/purchase/pro" : "/auth/signup?next=/purchase/pro"}
                            isBlock={true}
                        >
                            🚀 Start 14-day free trial
                        </Button>
                    </div>
                </div>
            </WelcomeLayout>
        </>
    );
}

export default requireAuth(WelcomePage);
