import {
  CurrencyDollarIcon,
  UserGroupIcon,
  CheckCircleIcon,
  CalendarIcon,
  CreditCardIcon,
  ArrowsRightLeftIcon,
  ChartBarIcon,
  UserPlusIcon,
  Battery50Icon,
  EyeSlashIcon,
  UserMinusIcon,
  ClockIcon,
  EyeIcon,
  BanknotesIcon,
  ArrowRightOnRectangleIcon

} from "@heroicons/react/20/solid";


export const columnMapping = {
  "ENPS Score": "enps",
  "Engagement Score": "engagement",
  "Regrettable Attrition": "regrettable_attrition",
  "Time To Offer": "time_to_hire",
  "Cost Of Hire": "cost_of_hire",
  "Annual Revenue Per Employee": "revenue_per_employee",
  "Promotion Percentage": "internal_mobility",
  "Absenteeism Rate": "absenteeism",
  "Average Tenure": "tenure",
  "Offer Acceptance Rate": "offer_acceptance_rate",
  "Probation Pass Rate": "probation_period_pass_rate",
  "People Team Percentage": "people_team_ratio",
  "Org Transparency": "org_transparency",
  "Early Attrition": "early_attrition",
  "IC : Manager Ratio": "manager_ic_ratio",
  "Time To Fill": "time_to_fill",
  "Non-Regrettable Attrition": "non_regrettable_attrition",
  "General & Administrative Burn Rate": "ga_burn_rate",
};
export const stats = [
  {
    id: 1,
    name: "ENPS Score",
    unit: "#",
    unitLocation: "front",
    icon: Battery50Icon,
    metricType: 'People',
    opinionated: true,
    howToCalculate: {
      description: "ENPS, or Employee Net Promoter Score, measures how likely employees are to recommend your company to friends. It's calculated on a scale from -100 to +100 by subtracting the percentage of detractors (0-6) from the percentage of promoters (9-10).",
      example: {
        scenario: "121 employees receive your ENPS survey",
        steps: [
          "90 respond with a score of between 0 & 6 out of 10",
          "10 respond with a score of 7 or 8 out of 10",
          "21 respond with a score of 9 or 10",
          "90 ÷ 121 x 100 = 74% Detractors",
          "(21 ÷ 121) x 100 = 17% Promoters",
          "ENPS = 17-74",
        ],
        result: "ENPS Score = -57",
      },
      conclusion: "So in this scenario, you would enter -57.",
    },
  },
  {
    id: 2,
    name: "Engagement Score",
    unit: "%",
    unitLocation: "end",
    icon: ChartBarIcon,
    metricType: 'People',
    opinionated: true,
    isCurrency: false,
    howToCalculate: {
      description: "Employee Engagement is typically assessed through surveys where employees provide feedback on various aspects of their work experience. It is calculated as an overall percentage of people feeling positive or engaged.",
      example: {
        scenario: "You run a 10 question engagement survey to 72 employees",
        steps: [
          "Each question's score is calculated based on the agree/disagree scale where strongly disagree = 1 and strongly agree = 5.",
          "Highest possible score per employee: 5 x 10 = 50",
          "Lowest possible score per employee: 1 x 10 = 10",
          "Total possible score: 50 x 72 = 3600",
          "Actual score after running your survey: 2000",
          "Engagement = (2000 ÷ 3600) x 100",
        ],
        result: "Engagement Score = 55%",
      },
      conclusion: "So in this scenario, you would enter 55.",
    },
  },
  {
    id: 3,
    name: "Regrettable Attrition",
    unit: "%",
    unitLocation: "end",
    icon: UserMinusIcon,
    metricType: 'People',
    opinionated: true,
    isCurrency: false,
    howToCalculate: {
      description: "Regrettable Attrition measures the percentage of employees who leave voluntarily due to factors within the company's control, where their departure negatively impacts business performance. These are typically high performers or employees in key roles that the company would have preferred to retain.",
      example: {
        scenario: "Out of a total team size of 100 employees, 5 employees, whose departure was preventable, left.",
        steps: [
          "Number of regrettable departures: 5",
          "Total number of employees: 100",
          "Regrettable Attrition = (5 / 100) x 100",
        ],
        result: "Regrettable Attrition = 5%",
      },
      conclusion: "In this scenario, you would enter 5.",
    },
  },
  {
    id: 4,
    name: "Time To Offer",
    unit: "Days",
    unitLocation: "end",
    icon: ClockIcon,
    metricType: 'Talent',
    opinionated: true,
    isCurrency: false,
    howToCalculate: {
      description: "Time To Offer measures the average number of days from when a candidate enters your pipeline to when an offer is extended.",
      example: {
        scenario: "You have 3 recent hires with the following Time To Offer durations: 20 days, 15 days, and 25 days",
        steps: [
          "Sum of all Time To Offer durations: 20 + 15 + 25 = 60 days",
          "Number of hires: 3",
          "Average Time To Offer = 60 / 3",
        ],
        result: "Average Time To Offer = 20 days",
      },
      conclusion: "So in this scenario, you would enter 20.",
    },
  },
  {
    id: 5,
    name: "Cost Of Hire",
    unit: "",
    unitLocation: "front",
    icon: CurrencyDollarIcon,
    metricType: 'Talent',
    opinionated: true,
    isCurrency: true,
    howToCalculate: {
      description: "Cost of hire measures the money you spent on hiring in a specific timeframe. It is calculated by adding up the money you spent on hiring, both the internal costs and external costs, and divide that total by the number of hires you've made in the period.",
      example: {
        scenario: "You made 17 hires in the last 12 months",
        steps: [
          "Your internal costs were $90,000",
          "Your external costs (agencies + ads) were $62,000",
          "Total costs: $90,000 + $62,000 = $152,000",
          "Cost of hire = $152,000 / 17",
        ],
        result: "Cost of hire = $8,941",
      },
      conclusion: "So in this scenario, you would enter 8941.",
    },
  },
  {
    id: 6,
    name: "Annual Revenue Per Employee",
    unit: "",
    unitLocation: "front",
    icon: CreditCardIcon,
    metricType: 'People',
    opinionated: true,
    isCurrency: true,
    howToCalculate: {
      description: "Annual revenue per employee measures the revenue generated per employee. It is calculated by taking the annual revenue of your business and dividing by the number of employees.",
      example: {
        scenario: "Your organisation generates $3,000,000 in revenue per year and you have 14 employees",
        steps: [
          "Annual revenue: $3,000,000",
          "Number of employees: 14",
          "Annual revenue per employee = $3,000,000 / 14",
        ],
        result: "Annual revenue per employee = $214,286",
      },
      conclusion: "So in this scenario, you would enter 214286.",
    },
  },
  {
    id: 7,
    name: "Promotion Percentage",
    unit: "%",
    unitLocation: "end",
    icon: ArrowsRightLeftIcon,
    metricType: 'People',
    opinionated: false,
    isCurrency: false,
    howToCalculate: {
      description: "Promotion rate is an internal mobility measure specifically focused on promotions. It is calculated as a percentage by dividing the total number of promotions by the total number of employees eligible for promotion and multiplying by 100.",
      example: {
        scenario: "Your organisation employs 247 people, 9 are executives or founders who are not promotable, and in the last 12 months you had 37 promotions",
        steps: [
          "Total employees: 247",
          "Non-promotable employees: 9",
          "Promotable employees: 247 - 9 = 238",
          "Number of promotions: 37",
          "Promotion rate = (37 / 238) * 100",
        ],
        result: "Promotion rate = 15.5%",
      },
      conclusion: "So in this scenario, you would enter 15.5.",
    },
  },
  {
    id: 8,
    name: "Absenteeism Rate",
    unit: "%",
    unitLocation: "end",
    icon: EyeSlashIcon,
    metricType: 'People',
    opinionated: true,
    isCurrency: false,
    howToCalculate: {
      description: "Absenteeism rate per person measures the average % of time employees were absent during a given period. It is calculated as a percentage.",
      example: {
        scenario: "Your organisation employs 112 people, you have 231 available work days in a 12 month period, and during that period, you had 75 instances of employee absence",
        steps: [
          "Number of employees: 112",
          "Available work days: 231",
          "Instances of absence: 75",
          "Absenteeism rate per person = (75 / 112) * (100 / 231)",
        ],
        result: "Average absenteeism rate per person = 0.28%",
      },
      conclusion: "So in this scenario, you would enter 0.28.",
    },
  },
  {
    id: 9,
    name: "Average Tenure",
    unit: "Months",
    unitLocation: "end",
    icon: CalendarIcon,
    metricType: 'People',
    opinionated: false,
    isCurrency: false,
    howToCalculate: {
      description: "Average Tenure tells you how long an employee generally stays with the organisation. It is calculated in months by taking the sum of all employees' tenure and dividing by the total number of employees.",
      example: {
        scenario: "Your organisation employs 192 people and the sum of their tenure is 5184 months",
        steps: [
          "Total employees: 192",
          "Sum of tenure: 5184 months",
          "Average Tenure = 5184 / 192",
        ],
        result: "Average Tenure = 27 months",
      },
      conclusion: "So in this scenario, you would enter 27.",
    },
  },
  {
    id: 10,
    name: "Offer Acceptance Rate",
    unit: "%",
    unitLocation: "end",
    icon: CheckCircleIcon,
    metricType: 'Talent',
    opinionated: true,
    isCurrency: false,
    howToCalculate: {
      description: "Offer acceptance rate tells you the proportion of candidates who accept your offers. It is calculated as a percentage.",
      example: {
        scenario: "You extended offers to 97 candidates in the last 12 months and 68 of those offers were accepted",
        steps: [
          "Offers extended: 97",
          "Offers accepted: 68",
          "Offer Acceptance Rate = (68 / 97) * 100",
        ],
        result: "Offer Acceptance Rate = 70.1%",
      },
      conclusion: "So in this scenario, you would enter 70.1.",
    },
  },
  {
    id: 11,
    name: "Probation Pass Rate",
    unit: "%",
    unitLocation: "end",
    icon: UserPlusIcon,
    metricType: 'People',
    opinionated: true,
    isCurrency: false,
    howToCalculate: {
      description: "Probation pass rate tells you the proportion of new joiners who pass probation. It is calculated as a percentage.",
      example: {
        scenario: "You had 37 new joiners in the last 12 months and 32 of those passed their probation",
        steps: [
          "New joiners: 37",
          "Passed probation: 32",
          "Probation Pass Rate = (32 / 37) * 100",
        ],
        result: "Probation Pass Rate = 86.5%",
      },
      conclusion: "So in this scenario, you would enter 86.5.",
    },
  },
  {
    id: 12,
    name: "People Team Percentage",
    unit: "%",
    unitLocation: "end",
    icon: UserGroupIcon,
    metricType: 'People',
    opinionated: false,
    isCurrency: false,
    howToCalculate: {
      description: "People Team Percentage measures the size of your People team as a % of the total headcount in your organisation. It is calculated as a percentage, by dividing the size of the people team by total employee count and multiplying by 100.",
      example: {
        scenario: "You have 78 people in your organisation and a 4 person people team",
        steps: [
          "Total employees: 78",
          "People team size: 4",
          "People Team Percentage = (4 / 78) * 100",
        ],
        result: "People Team Percentage = 5.1%",
      },
      conclusion: "So in this scenario, you would enter 5.1.",
    },
  },
  {
    id: 13,
    name: "Org Transparency",
    unit: "#",
    unitLocation: "front",
    icon: EyeIcon,
    metricType: 'People',
    opinionated: true,
    isCurrency: false,
    howToCalculate: {
      description: "Org transparency measures the extent to which a business is transparent about their cultural practices and ways of working both internally & externally. It is calculated as a number, by assessing 30+ cultural dimensions via a 10 min assessment.",
      example: {
        scenario: "To calculate your Org Transparency score:",
        steps: [
          "Take the org transparency assessment here to get your score",
          "Complete the 10-minute assessment",
          "Receive your org transparency score"
        ],
        result: "Your org transparency score (e.g., 75)"
      },
      conclusion: "Enter the score you received from the assessment.",
      externalAssessment: {
        linkText: "Take org transparency assessment",
        url: "http://adam-4r7asmm6.scoreapp.com/",
      },
    },
  },
  {
    id: 14,
    name: "Early Attrition",
    unit: "%",
    unitLocation: "end",
    icon: UserMinusIcon,
    metricType: 'People',
    opinionated: true,
    isCurrency: false,
    howToCalculate: {
      description: "Early Attrition measures the rate of people who leave the company within the first 90 days of joining. It is calculated as a percentage, by dividing the number of people who leave in a 90 day period and multiplying by 100.",
      example: {
        scenario: "28 people join your company in the past 90 days and 4 of those people leave within that period",
        steps: [
          "New joiners in 90 days: 28",
          "Leavers within 90 days: 4",
          "Early Attrition = (4 / 28) * 100",
        ],
        result: "Early Attrition = 14.2%",
      },
      conclusion: "So in this scenario, you would enter 14.2.",
    },
  },
  {
    id: 15,
    name: "IC : Manager Ratio",
    unit: "#",
    unitLocation: "front",
    icon: UserGroupIcon,
    metricType: 'People',
    opinionated: false,
    isCurrency: false,
    howToCalculate: {
      description: "IC : Manager ratio measures the ratio of individual contributors to people managers. It is calculated by dividing the number of direct reports by the number of people managers (inc. executives).",
      example: {
        scenario: "You have 32 people managers and they support 122 individual contributors",
        steps: [
          "Number of managers: 32",
          "Number of ICs: 122",
          "IC : Manager ratio = 122 / 32",
        ],
        result: "IC : Manager ratio = 3.5",
      },
      conclusion: "So in this scenario, you would enter 3.5.",
    },
  },
  {
    id: 16,
    name: "Time To Fill",
    unit: "Days",
    unitLocation: "end",
    icon: ClockIcon,
    metricType: 'Talent',
    opinionated: true,
    isCurrency: false,
    howToCalculate: {
      description: "Time to fill measures the number of days from a job opening going 'live' to the moment an offer is accepted. It is calculated by subtracting the day the successful candidate accepted the job offer from the day the job was opened or went live.",
      example: {
        scenario: "You opened 7 jobs with the following Time to Fill durations: [30, 32, 37, 42, 39, 31, 28] days",
        steps: [
          "Sum of all Time to Fill durations: 30 + 32 + 37 + 42 + 39 + 31 + 28 = 239 days",
          "Number of jobs: 7",
          "Average Time To Fill = 239 / 7",
        ],
        result: "Average Time To Fill = 34.1 Days",
      },
      conclusion: "So in this scenario, you would enter 34.1.",
    },
  },
  {
    id: 17,
    name: "Non-Regrettable Attrition",
    unit: "%",
    unitLocation: "end",
    icon: ArrowRightOnRectangleIcon,
    metricType: 'People',
    opinionated: true,
    isCurrency: false,
    howToCalculate: {
      description: "Non-Regrettable Attrition measures the percentage of employees whose departure is either outside the company's control or has no negative effect on business performance. This includes exits due to retirement, poor performance, cultural misalignment, or mutual separation.",
      example: {
        scenario: "Out of 271 employees, 16 employees, whose departures were either expected or manageable, left.",
        steps: [
          "Total employees: 271",
          "Non-regrettable departures: 16",
          "Non-regrettable attrition = (16 / 271) * 100",
        ],
        result: "Non-regrettable attrition = 5.9%",
      },
      conclusion: "In this scenario, you would enter 5.9.",
    },
  },
  {
    id: 19,
    name: "General & Administrative Burn Rate",
    unit: "#",
    unitLocation: "front",
    icon: BanknotesIcon,
    metricType: 'People',
    opinionated: true,
    isCurrency: false,
    howToCalculate: {
      description: "General & Administrative Burn Ratio measures total people costs (Salary & Taxes) minus Monthly Recurring Revenue (MRR). It is calculated by dividing total people costs by MRR, and is a super useful metric for startups focusing on getting to profitability.",
      example: {
        scenario: "Your monthly spend on people costs is $250k and your MRR is $100k",
        steps: [
          "Monthly people costs: $250k",
          "Monthly Recurring Revenue (MRR): $100k",
          "GA Burn = 250k / 100k",
        ],
        result: "GA Burn Rate = 2.5",
      },
      conclusion: "So in this scenario, you would enter 2.5.",
    },
  },
];

export const options = [
  { value: "Seed", label: "Seed" },
  { value: "Bootstrapped", label: "Bootstrapped" },
  { value: "Series A", label: "Series A" },
  { value: "Series B", label: "Series B" },
  { value: "Series C+", label: "Series C+" },
  { value: ["Seed", "Bootstrapped", "Series A", "Series B", "Series C+"], label: "All" },
];

export const headcountOptions = [
  { value: "0-10", label: "0-10" },
  { value: "11-50", label: "11-50" },
  { value: "51-100", label: "51-100" },
  { value: "101-250", label: "101-250" },
  { value: "251-500", label: "251-500" },
  { value: "501-1000", label: "501-1000" },
  { value: "1001+", label: "1001+" },
  { value:  ["0-10", "11-50", "51-100", "101-250", "251-500", "501-1000", "1001+"], label: "All" },
];

export const locationOptions = [
  { name: "🌎 Remote", code: "Remote" },
  { name: "🇺🇸 United States", code: "US" },
  { name: "🇬🇧 United Kingdom", code: "UK" },
  { name: "🇨🇦 Canada", code: "CA" },
  { name: "🇦🇺 Australia", code: "AU" },
  { name: "🇩🇪 Germany", code: "DE" },
  { name: "🇫🇷 France", code: "FR" },
  { name: "🇯🇵 Japan", code: "JP" },
  { name: "🇮🇳 India", code: "IN" },
  { name: "🇨🇳 China", code: "CN" },
  { name: "🇧🇷 Brazil", code: "BR" },
  { name: "🇸🇬 Singapore", code: "SG" },
  { name: "🇳🇱 Netherlands", code: "NL" },
  { name: "🇮🇪 Ireland", code: "IE" },
  { name: "🇸🇪 Sweden", code: "SE" },
  { name: "🇫🇮 Finland", code: "FI" },
  { name: "🇳🇴 Norway", code: "NO" },
  { name: "🇩🇰 Denmark", code: "DK" },
  { name: "🇿🇦 South Africa", code: "ZA" },
  { name: "🇮🇱 Israel", code: "IL" },
  { name: "🇷🇺 Russia", code: "RU" },
  { name: "🇰🇷 South Korea", code: "KR" },
  { name: "🇪🇸 Spain", code: "ES" },
  { name: "🇮🇹 Italy", code: "IT" },
  { name: "🇹🇷 Turkey", code: "TR" },
  { name: "🇦🇪 United Arab Emirates", code: "AE" },
  { name: "🇲🇽 Mexico", code: "MX" },
  { name: "🇧🇪 Belgium", code: "BE" },
  { name: "🇨🇭 Switzerland", code: "CH" },
  { name: "🇵🇱 Poland", code: "PL" },
  { name: "🇭🇺 Hungary", code: "HU" },

];


export const regionOptions = [
  { value: "Europe", label: "Europe", countries: ['UK', 'DE', 'FR', 'NL', 'IE', 'SE', 'FI', 'NO', 'DK', 'BE', 'CH', 'PL', 'HU', 'ES', 'IT', 'RU'] },
  { value: "North America", label: "North America", countries: ['US', 'CA', 'MX'] },
  { value: "All", label: "All", countries: ['Remote', 'UK', 'US', 'CA', 'AU', 'DE', 'FR', 'JP', 'IN', 'CN', 'BR', 'SG', 'NL', 'IE', 'SE', 'FI', 'NO', 'DK', 'ZA', 'IL', 'RU', 'KR', 'ES', 'IT', 'TR', 'AE', 'MX', 'BE', 'CH', 'PL', 'HU']
},
  // { value: "Remote", label: "Remote", countries: ['Remote'] },
  // { value: "Asia Pacific", label: "Asia Pacific", countries: ['AU', 'JP', 'IN', 'CN', 'SG', 'KR'] },
  // { value: "Middle East", label: "Middle East", countries: ['AE', 'IL'] },
  // { value: "South America", label: "South America", countries: ['BR'] },
  // { value: "Africa", label: "Africa", countries: ['ZA'] },
];


//commenting out the regionOptions until we have more data

// util/metricUtils.js

export const timePeriodOptions = [
  { value: 'Current Quarter', label: 'Current Quarter' },
  { value: 'Previous Quarter', label: 'Previous Quarter' },
  { value: 'Previous Quarter minus 1', label: 'Previous Quarter minus 1' },
  { value: 'Previous Quarter minus 2', label: 'Previous Quarter minus 2' },
  { value: 'Average of last 6 months', label: 'Average of last 6 months' },
  { value: 'Average of last 12 months', label: 'Average of last 12 months' }
];
const d3 = require("d3");

export const calculateUserPercentile = (metricValue, metricData) => {
  const validData = metricData.filter(value => !isNaN(value) && value !== null).sort((a, b) => a - b);

  if (validData.length === 0) {
    return null;
  }

  // Count how many values are less than or equal to the metric value
  const countBelow = validData.filter(value => value <= metricValue).length;
  
  // Calculate percentile as percentage of values at or below
  const percentile = (countBelow / validData.length) * 100;

  return percentile;
};

export const calculateQuarterlyTrend = (currentValue, previousValue) => {
  if (currentValue === null || previousValue === null || isNaN(currentValue) || isNaN(previousValue)) {
    return null;
  }

  // Calculate the absolute difference
  const change = ((currentValue - previousValue) / Math.abs(previousValue)) * 100;

  let changeType = 'no-change';
  if (change > 0) {
    changeType = 'increase';
  } else if (change < 0) {
    changeType = 'decrease';
  }

  return {
    change: `${Math.abs(change).toFixed(2)}%`,
    changeType: changeType
  };
};


export const getCurrentQuarter = () => {
  const month = new Date().getMonth() + 1;
  const quarter = Math.ceil(month / 3);
  return `Q${quarter}`;
};

export const checkForCurrentQuarterMetrics = (metrics) => {
  if (!metrics || metrics.length === 0) return false;

  const currentQuarter = getCurrentQuarter();

  return metrics.some(metric => {
    if (metric.time_period === currentQuarter) {
      return stats.some(stat => {
        const columnName = columnMapping[stat.name];
        const metricValue = parseFloat(metric[columnName]);
        return !isNaN(metricValue) && metricValue !== null;
      });
    }
    return false;
  });
};

export const calculateMetricStats = (value, data, metricName) => {
  // Add debug logging
  
  if (value === undefined || value === null || !data || !Array.isArray(data) || data.length === 0) {
    return {
      percentile: null,
      quartile: null,
      isInverted: false,
      ranges: {
        low: null,
        mid: null,
        high: null
      }
    };
  }

  // Convert value to number if it's a string
  const numericValue = Number(value);
  
  // Filter out any non-numeric values from data
  const cleanData = data.filter(v => !isNaN(Number(v)));
  
  if (isNaN(numericValue) || cleanData.length === 0) {
    console.log('Invalid numeric value or no clean data');
    return null;
  }

  const isInverted = invertedMetrics.includes(metricName);
  
  // Sort numeric data (for inverted metrics, we reverse the sort order)
  const sortedData = [...cleanData].sort((a, b) => 
    isInverted ? Number(b) - Number(a) : Number(a) - Number(b)
  );
  
  // For inverted metrics, we count values greater than or equal
  // For regular metrics, we count values less than or equal
  const position = isInverted
    ? sortedData.filter(v => Number(v) >= numericValue).length
    : sortedData.filter(v => Number(v) <= numericValue).length;

  const percentile = (position / sortedData.length) * 100;

  // Calculate quartile (1-4)
  const quartile = Math.ceil(percentile / 25);
  
  // Calculate ranges (note: for inverted metrics, these are already in correct order due to sort)
  const ranges = {
    low: {
      min: sortedData[0],
      max: sortedData[Math.floor(sortedData.length * 0.1)]
    },
    mid: sortedData[Math.floor(sortedData.length * 0.5)],
    high: {
      min: sortedData[Math.floor(sortedData.length * 0.9)],
      max: sortedData[sortedData.length - 1]
    }
  };

  return {
    percentile,
    quartile,
    isInverted,
    ranges,
    min: sortedData[0],
    max: sortedData[sortedData.length - 1]
  };
};

// Helper for formatting numbers with suffixes (1st, 2nd, 3rd, etc.)
export const getOrdinalSuffix = (n) => {
  if (n >= 11 && n <= 13) return `${n}th`;
  const lastDigit = n % 10;
  const suffixes = ['th', 'st', 'nd', 'rd'];
  return `${n}${suffixes[lastDigit] || suffixes[0]}`;
};

export const invertedMetrics = [
  "Time To Offer",
  "Time To Fill",
  "Cost Of Hire",
  "Regrettable Attrition",
  "Non-Regrettable Attrition",
  "Early Attrition",
  "Absenteeism Rate",
  "General & Administrative Burn Rate"
];