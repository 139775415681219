import React from "react";
import Meta from "../components/settings/Meta";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import { requireAuth } from "../util/auth";
import { motion } from "framer-motion";

function DataStoryPage() {
  return (
    <>
      <Meta title="Discover Your Data Stories" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="relative">
          {/* Background decoration */}
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute -top-40 -right-32 w-96 h-96 bg-gradient-to-br from-indigo-100/30 to-purple-100/30 rounded-full blur-3xl" />
            <div className="absolute -bottom-40 -left-32 w-96 h-96 bg-gradient-to-tr from-blue-100/30 to-emerald-100/30 rounded-full blur-3xl" />
          </div>
          
          <DashboardLayout />
        </div>
      </motion.div>
    </>
  );
}

export default requireAuth(DataStoryPage); 