import React from 'react';
import algolia from '../../images/logos/Algolia-logo.svg.png';
import auror from '../../images/logos/auror.svg';
import etsy from '../../images/logos/Etsy_logo.svg.png';
import trint from '../../images/logos/Trint_Logo.svg.png';
import vidyard from '../../images/logos/vidyard_logo_2.png';
import circuit from '../../images/logos/circuit.svg';
import griffin from '../../images/logos/griffin_logo.svg';
import nebulab from '../../images/logos/nebulab_logo.svg';
import assetclass from '../../images/logos/asset_class_logo.png';
import notion from '../../images/logos/notion_logo.png';
import stripe from '../../images/logos/stripe_logo.png';
import charlieHR from '../../images/logos/charlie_logo.png';
import carescribe from '../../images/logos/carescribe_logo.png';
import capchase from '../../images/logos/capchase_logo.png';
import stability from '../../images/logos/stability_ai_logo.png';

// Define logos with their metadata
const logos = [
  { src: vidyard, alt: "Vidyard", size: "large" },
  { src: etsy, alt: "Etsy", size: "large" },
  { src: trint, alt: "Trint", size: "large" },
  { src: algolia, alt: "Algolia", size: "large" },
  { src: auror, alt: "Auror", size: "large" },
  { src: circuit, alt: "Circuit", size: "large" },
  { src: griffin, alt: "Griffin Bank", size: "large" },
  { src: nebulab, alt: "Nebulab", size: "large" },
  { src: assetclass, alt: "Asset Class", size: "large" },
  { src: notion, alt: "Notion", size: "large" },
  { src: stripe, alt: "Stripe", size: "large" },
  { src: charlieHR, alt: "Charlie HR", size: "large" },
  { src: carescribe, alt: "CareScribe", size: "large" },
  { src: capchase, alt: "Capchase", size: "large" },
  { src: stability, alt: "Stability AI", size: "large" },

  // Add more logos here...
];

export default function LogoCloud() {
  return (
    <div className="relative bg-transparent py-12 sm:py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 className="text-center text-base sm:text-lg font-semibold leading-8 text-gray-700 px-4">
        Trusted by scale-ups from seed to Series C and beyond.        </h2>
        
        {/* Logo grid instead of scroll for better reliability */}
        <div className="mx-auto mt-8 grid grid-cols-4 items-center gap-x-8 gap-y-10 sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:grid-cols-5">
          {logos.map((logo, idx) => (
            <div
              key={`logo-${idx}`}
              className="relative flex items-center justify-center transition-all duration-300 hover:scale-110"
            >
              <img
                src={logo.src}
                alt={logo.alt}
                className={`object-contain ${
                  logo.size === 'large' ? 'h-12 sm:h-16' : 'h-10 sm:h-12'
                } w-full grayscale hover:grayscale-0 transition-all duration-300`}
                onError={(e) => {
                  console.error(`Failed to load logo: ${logo.alt}`);
                  e.target.style.display = 'none';
                }}
              />
            </div>
          ))}
        </div>

        {/* Trust indicators */}
        <div className="mt-8 sm:mt-10 flex justify-center gap-x-4 sm:gap-x-6 gap-y-4 flex-wrap text-xs sm:text-sm leading-6 text-gray-600">
          <div className="flex items-center gap-x-2">
            <svg className="h-4 w-4 sm:h-5 sm:w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" />
            </svg>
            Verified companies
          </div>
          <div className="flex items-center gap-x-2">
            <svg className="h-4 w-4 sm:h-5 sm:w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor">
              <path d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684z" />
            </svg>
            Verified People Leaders
          </div>
        </div>
      </div>
    </div>
  );
}
  